import { IconName } from 'constants/iconName';

import React from 'react';
import type { FC } from 'react';
import type { DataGridProps as MuiDataGridProps } from '@mui/x-data-grid';
import Pagination from 'components/molecules/Pagination';
import SvgIcon from 'components/atoms/SvgIcon';

import { StyledDataGrid } from './DataGrid.styles';

interface DataGridProps extends MuiDataGridProps {}

const DataGrid: FC<DataGridProps> = (props) => {
  return (
    <StyledDataGrid
      density="compact"
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
      }}
      autoHeight
      pageSizeOptions={[50]}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      disableColumnResize
      slots={{
        pagination: Pagination,
        columnUnsortedIcon: () => <SvgIcon name={IconName.Unsorted} />,
        columnSortedAscendingIcon: () => <SvgIcon name={IconName.SortedAscending} />,
        columnSortedDescendingIcon: () => <SvgIcon name={IconName.SortedDescending} />,
      }}
      {...props}
    />
  );
};

export default DataGrid;
