import React, { forwardRef } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Typography } from '@mui/material';

import { StyledFormControlLabel, StyledCheckbox } from './FormCheckbox.styles';

interface FormCheckboxProps extends Omit<ControllerRenderProps, 'ref'> {
  label: string;
  disabled?: boolean;
}

const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>(({ label, disabled = false, ...field }, ref) => {
  return (
    <StyledFormControlLabel
      control={
        <StyledCheckbox
          size="small"
          disabled={disabled}
          inputRef={ref}
          checked={field.value ?? false}
          onChange={field.onChange}
        />
      }
      label={<Typography sx={{ fontSize: 14 }}>{label}</Typography>}
      disabled={disabled}
    />
  );
});

export default FormCheckbox;
