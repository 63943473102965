import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CasinoSnapshotsForm from './subcomponents/CasinoSnapshotsForm';
import SnapshotForm from './subcomponents/SnapshotForm';
import { Box } from '@mui/material';

const Snapshot = () => {
  return (
    <Box ml={20} mr={20} p={3}>
      <Typography variant="h4" align="center" gutterBottom>
        New Snapshot
      </Typography>
      <Grid container spacing={6}>
        <Grid item sm={6}>
          <SnapshotForm />
        </Grid>
        <Grid item sm={6}>
          <CasinoSnapshotsForm />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Snapshot;
