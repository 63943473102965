// TODO: this implementation is used in Cashflows, so I keep it until Cashflow will be reworked
import React, { Children, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export function TabPanel(props) {
  const { children, value = '', index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any,
  value: PropTypes.any,
};

export default function SimpleTabs({ children, activeIndex = 0, onChange, addButton, ...props }) {
  const [value, setValue] = React.useState(activeIndex);

  useEffect(() => {
    setValue(activeIndex);
  }, [activeIndex]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (typeof onChange === 'function') {
      onChange(newValue);
    }
  };

  const childrenArray = useMemo(
    () =>
      Children.map(children, ({ props: { id, title } }) => ({
        id,
        title,
      })),
    [children],
  );

  useEffect(() => {
    if (!(activeIndex > childrenArray.length - 1)) {
      return;
    }

    if (typeof onChange === 'function') {
      onChange(childrenArray.length - 1);
    }
  }, [childrenArray, activeIndex, onChange]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Tabs value={value} onChange={handleChange} {...props}>
          {childrenArray.map(({ title, id }) => (
            <Tab key={id} label={title} />
          ))}
        </Tabs>
        {addButton}
      </Box>

      {Children.map(children, (tab, index) => (
        <TabPanel key={tab.props.id} value={value} index={index}>
          {tab.props.children}
        </TabPanel>
      ))}
    </div>
  );
}
