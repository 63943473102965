import { Autocomplete, Checkbox, FormHelperText, type AutocompleteRenderGetTagProps } from '@mui/material';
import React, { forwardRef } from 'react';
import type { ControllerRenderProps, FieldError } from 'react-hook-form';

import { StyledMenuItem, StyledFormControl, StyledChip, StyledTextField } from './FormMultiSelect.styles';

export interface MultiSelectOption {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface MultiSelectProps extends Omit<ControllerRenderProps, 'ref'> {
  label: string;
  options: MultiSelectOption[];
  required?: boolean;
  limitTags?: boolean;
  error?: FieldError;
}

const FormMultiSelect = forwardRef(
  ({ label, options, onChange, value, required, error, limitTags = false }: MultiSelectProps, ref) => {
    const handleDelete = (optionToDelete: string | number) => {
      const newValue = value.filter((option: string | number) => option !== optionToDelete);
      onChange(newValue);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: Array<string | number>) => {
      onChange(newValue);
    };

    const renderTags = (selected: string[] | number[], getTagProps: AutocompleteRenderGetTagProps) => {
      const sortedTags = selected
        .map((selectedValue) => options.find((option) => option.value === selectedValue))
        .filter((option) => !!option)
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

      if (limitTags) {
        const firstTag = sortedTags[0];
        return (
          <>
            <StyledChip
              {...getTagProps({ index: 0 })}
              key={firstTag.value}
              label={firstTag.label}
              onMouseDown={(event) => event.stopPropagation()}
              onDelete={() => handleDelete(firstTag.value)}
              variant="outlined"
              size="small"
            />

            {sortedTags.length > 1 && (
              <StyledChip variant="outlined" size="small" label={`+${sortedTags.length - 1}`}></StyledChip>
            )}
          </>
        );
      }

      return sortedTags.map((option, index) => (
        <StyledChip
          {...getTagProps({ index })}
          key={option.value}
          label={option.label}
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => handleDelete(option.value)}
          variant="outlined"
          size="small"
        />
      ));
    };

    return (
      <>
        <StyledFormControl size="small" fullWidth>
          <Autocomplete
            multiple
            size="small"
            fullWidth
            disableClearable
            disableCloseOnSelect
            options={options.map((option) => option.value)}
            getOptionLabel={(option) => options.find((opt) => opt.value === option)?.label ?? ''}
            getOptionDisabled={(option) => options.find((opt) => opt.value === option)?.disabled ?? false}
            value={value}
            onChange={handleChange}
            renderTags={(selected: string[] | number[], getTagProps) => renderTags(selected, getTagProps)}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                size="small"
                label={label}
                InputLabelProps={{ shrink: true }}
                required={required}
                error={!!error}
                inputRef={ref}
              />
            )}
            renderOption={(props, option, { selected }) => {
              const foundOption = options.find((opt) => opt.value === option);
              return (
                <StyledMenuItem {...props}>
                  <Checkbox checked={selected} sx={{ p: 0, pr: 1 }} />
                  {foundOption?.label ?? ''}
                </StyledMenuItem>
              );
            }}
          />
        </StyledFormControl>
        {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
      </>
    );
  },
);

export default FormMultiSelect;
