import { combineEpics, ofType } from 'redux-observable';
import { ignoreElements, mergeMapTo, mapTo, tap } from 'rxjs/operators';
import { NotificationManager } from 'react-notifications';
import { loadCurrenciesAction } from 'api/currencies';
import { saveCashflowFulfilledAction, saveCashflowRejectedAction } from 'api/cashflows';

import { initCashflowFormAction, resetCashflowFormAction } from './index';

const initPaymentSystemFormEpic = (action$) =>
  action$.pipe(ofType(initCashflowFormAction), mergeMapTo([loadCurrenciesAction()]));

const onSaveCashflowSuccess = (action$) =>
  action$.pipe(
    ofType(saveCashflowFulfilledAction),
    tap(() => NotificationManager.success('Cashflow created')),
    mapTo(resetCashflowFormAction()),
  );

const onSaveCashflowFail = (action$) =>
  action$.pipe(
    ofType(saveCashflowRejectedAction),
    tap(() => NotificationManager.error('Failed to create cashflow')),
    ignoreElements(),
  );

export default combineEpics(initPaymentSystemFormEpic, onSaveCashflowSuccess, onSaveCashflowFail);
