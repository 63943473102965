import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { ignoreElements, mapTo, tap, mergeMap } from 'rxjs/operators';
import { NotificationManager } from 'react-notifications';
import { saveSnapshotFulfilledAction, saveSnapshotRejectedAction } from 'api/snapshots';

import { setCasinoSnapshotsIdAction } from '../casino-snapshots-form';

import { resetSnapshotFormAction } from './index';

const onSaveSnapshotSuccess = (action$) =>
  action$.pipe(
    ofType(saveSnapshotFulfilledAction),
    tap(() => {
      return NotificationManager.success('Snapshot created');
    }),
    mergeMap(({ payload }) => {
      return of(resetSnapshotFormAction(), setCasinoSnapshotsIdAction(payload.response.id));
    }),
  );

const onSaveSnapshotFail = (action$) =>
  action$.pipe(
    ofType(saveSnapshotRejectedAction),
    tap(() => {
      NotificationManager.error('Failed to create snapshot');
    }),
    ignoreElements(),
  );

export default combineEpics(onSaveSnapshotSuccess, onSaveSnapshotFail);
