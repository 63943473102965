import { createActions, handleActions } from 'redux-actions';
import { noop } from 'rxjs';
import identity from 'ramda/src/identity';

const DEFAULT_STATE = {
  title: '',
  data: '',
};

export const { setSnapshotTitleAction, setSnapshotDataAction, resetSnapshotFormAction } = createActions(
  {
    SET_SNAPSHOT_TITLE_ACTION: identity,
    SET_SNAPSHOT_DATA_ACTION: identity,
    RESET_SNAPSHOT_FORM_ACTION: noop,
  },
  {
    prefix: 'SNAPSHOT_FORM',
  },
);

export const snapshotFormReducer = handleActions(
  {
    [setSnapshotTitleAction]: (state, { payload }) => ({ ...state, title: payload }),
    [setSnapshotDataAction]: (state, { payload }) => ({ ...state, data: payload }),
    [resetSnapshotFormAction]: () => ({ ...DEFAULT_STATE }),
  },
  DEFAULT_STATE,
);
