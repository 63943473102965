import styled from 'styled-components';
import { FormControl, MenuItem, Select } from '@mui/material';

export const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    color: ${({ theme }) => theme.palette.text.dark};
  }
`;

export const StyledFormControl = styled(FormControl)`
  background-color: ${({ theme }) => theme.palette.background.paper};

  & .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  & .MuiSelect-select.Mui-disabled {
    color: ${({ theme }) => theme.palette.text.disabledControl};
    background-color: ${({ theme }) => theme.palette.background.disabledControl};
  }

  & .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.background.style2};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  white-space: normal;
  word-wrap: break-word;
  color: ${({ theme }) => theme.palette.text.dark};
  min-height: 2rem;
`;
