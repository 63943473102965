import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DataGrid from 'components/molecules/DataGrid';
import StyledLink from 'components/atoms/Link';
import { cashflowsResponseSelector, loadCashflowsAction } from 'api/cashflows';
import AddButton from 'components/atoms/AddButton';
import StyledBox from 'components/atoms/Box';

const Cashflows = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCashflowsAction());
  }, [dispatch]);

  const cashflows = useSelector(cashflowsResponseSelector);

  return (
    <Box>
      <StyledBox>
        <StyledLink to="/cashflows/add">
          <AddButton>Add cashflow</AddButton>
        </StyledLink>
      </StyledBox>

      <DataGrid
        columns={[
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          {
            field: 'currency',
            headerName: 'Currency',
            flex: 1,
          },
          {
            field: 'acceptableCurrencies',
            headerName: 'Acceptable currencies',
            flex: 1,
            valueFormatter: ({ value }) => value?.length && value.join(', '),
          },
        ]}
        rows={cashflows}
      />
    </Box>
  );
};

export default Cashflows;
