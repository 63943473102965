import styled from 'styled-components';

export const StyledActionButton = styled.button`
  padding: 0 6px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  font-size: 0.625rem;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.secondary};
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background-color: transparent;

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.style2};
  }

  &:active {
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: transparent;
  }
`;
