import { combineEpics, type Epic } from 'redux-observable';
import { mergeMapTo, map, filter } from 'rxjs/operators';
import { loadPaymentProviders } from 'api/payment-providers';
import { loadTagsAction } from 'api/tags';
import { loadCurrenciesAction } from 'api/currencies';
import { loadCasinosAction } from 'api/casinos';
import { forceLoadAccessDataAction } from 'api/access-data';
import { loadCashflowsAction } from 'api/cashflows';
import { loadLimitsAction } from 'api/limits';
import { loadPaymentSystemFulfilledAction } from 'api/payment-systems';
import type { RootAction } from 'types/sotre/actions/root';
import type { RootState } from 'types/sotre/state/root';
import { isActionOf } from 'typesafe-actions';

import { initPaymentSystemFormAction } from './actions';

const initPaymentSystemFormEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(initPaymentSystemFormAction)),
    mergeMapTo([
      loadPaymentProviders(),
      loadTagsAction(),
      loadCurrenciesAction(),
      loadCasinosAction(),
      loadCashflowsAction(),
      loadLimitsAction(),
    ]),
  );

const loadPaymentSystemSuccessEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(loadPaymentSystemFulfilledAction)),
    map(({ payload: { response } }) => forceLoadAccessDataAction(response.payment_provider.name)),
  );

export default combineEpics(initPaymentSystemFormEpic, loadPaymentSystemSuccessEpic);
