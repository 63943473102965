import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import Cashflows from 'pages/Cashflows';
import PaymentSystems from 'pages/PaymentSystems';
import Snapshots from 'pages/Snapshots';
import 'react-notifications/lib/notifications.css';
import GlobalLoading from 'components/atoms/GlobalLoading';

import theme from './theme/index';
import Header from './components/molecules/Header';
import { AppWrapper, PageWrapper } from './App.styles';

const App = () => (
  <ThemeProvider theme={theme}>
    <AppWrapper maxWidth={false}>
      <NotificationContainer />
      <Router>
        <Header />
        <PageWrapper maxWidth={false}>
          <Routes>
            <Route path="/payment_systems/*" element={<PaymentSystems />} />
            <Route path="/cashflows/*" element={<Cashflows />} />
            <Route path="/snapshots/*" element={<Snapshots />} />
            <Route path="*" element={<Navigate to="/payment_systems" />} />
          </Routes>
        </PageWrapper>
      </Router>
      <GlobalLoading />
    </AppWrapper>
  </ThemeProvider>
);

export default App;
