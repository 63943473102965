import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getTags = (): Promise<string[]> => axiosInstance.get(ENDPOINTS.TAGS);

export const { loadDataAction: loadTagsAction, responseSelector: tagsResponseSelector } = requestsFactory({
  request: getTags,
  stateRequestKey: 'tags',
  transformResponse: (response?: string[]) => response ?? [],
});

const getFilterTags = (): Promise<string[]> => axiosInstance.get(ENDPOINTS.FILTER_TAGS);

export const { loadDataAction: loadFilterTags, responseSelector: filterTagsResponseSelector } = requestsFactory({
  request: getFilterTags,
  stateRequestKey: 'filter-tags',
  transformResponse: (response?: string[]) => response ?? [],
  includeInGlobalLoading: false,
});
