import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ({ options, labelProperty, valueProperty, onChange, value = [], ...props }) {
  const [state, setState] = React.useState(value.reduce((acc, valueKey) => ({ ...acc, [valueKey]: true }), {}));

  const handleChange = (event) => {
    const nextState = { ...state, [event.target.name]: event.target.checked };
    setState(nextState);

    if (onChange) {
      onChange(
        Object.keys(nextState).reduce((acc, key) => {
          if (nextState[key]) {
            acc.push(key);
          }

          return acc;
        }, []),
      );
    }
  };

  return (
    <FormGroup {...props}>
      {options.map((option) => (
        <FormControlLabel
          key={option[valueProperty]}
          control={
            <Checkbox checked={state[option[labelProperty]]} onChange={handleChange} name={option[valueProperty]} />
          }
          label={option[labelProperty]}
        />
      ))}
    </FormGroup>
  );
}
