import { filterCurrenciesResponseSelector } from 'api/currencies';
import { filterPaymentProvidersResponseSelector } from 'api/payment-providers';
import { filterChildSystemsResponseSelector } from 'api/payment-systems';
import { filterStatusesResponseSelector } from 'api/statuses';
import { filterTagsResponseSelector } from 'api/tags';
import { createSelector } from 'reselect';
import { getStatusText } from 'utils/paymentSystemStatusHelper';

export const filterCurrenciesSelector = createSelector(filterCurrenciesResponseSelector, (filterCurrencies) =>
  filterCurrencies.map((value) => ({ label: value, value: value })),
);

export const filterPaymentProvidersSelector = createSelector(
  filterPaymentProvidersResponseSelector,
  (filterPaymentProviders) => filterPaymentProviders.map((value) => ({ label: value, value: value })),
);

export const filterChildSystemsSelector = createSelector(filterChildSystemsResponseSelector, (filterChildSystems) =>
  filterChildSystems.map((value) => ({ label: value, value: value })),
);

export const filterTagsSelector = createSelector(filterTagsResponseSelector, (filterTags) =>
  filterTags.map((value) => ({ label: value, value: value })),
);

export const filterStatusesSelector = createSelector(filterStatusesResponseSelector, (filterStatuses) =>
  filterStatuses.map((value) => ({ label: getStatusText(value), value: value })),
);
