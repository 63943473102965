import { createSelector } from 'reselect';
import isNil from 'ramda/src/isNil';
import values from 'ramda/src/values';

export const cashflowFormSelector = (state) => state.cashflowForm;

const withBackup = (value, defaultValue) => (isNil(value) || value === '' ? defaultValue : value);
export const cashflowBodySelector = createSelector([cashflowFormSelector], (cashflowForm) => {
  const { name, currency, acceptableCurrencies: acceptable_currencies, commissions } = cashflowForm;

  const { default: defaultCommission, ...customCommissions } = commissions;
  const {
    deposit: defaultDeposit,
    cashout: defaultCashout,
    refund: defaultRefund,
    chargeback: defaultChargeback,
  } = defaultCommission;

  const body = {
    name,
    currency,
    acceptable_currencies,
    commissions: values(customCommissions).map((service) => {
      const { serviceName, deposit, cashout, refund, chargeback } = service;
      return {
        service_name: serviceName,

        deposit_commission_percent: withBackup(deposit.percent, defaultDeposit.percent),
        deposit_commission_fixed: withBackup(deposit.fixed, defaultDeposit.fixed),
        deposit_commission_fixed_currency: withBackup(deposit.fixedCurrency, defaultDeposit.fixedCurrency),
        deposit_min_commission: withBackup(deposit.min, defaultDeposit.min),
        deposit_min_commission_currency: withBackup(deposit.minCurrency, defaultDeposit.minCurrency),
        deposit_failed_commission_fixed: withBackup(deposit.failed, defaultDeposit.failed),
        deposit_failed_commission_fixed_currency: withBackup(deposit.failedCurrency, defaultDeposit.failedCurrency),
        deposit_max_commission: withBackup(deposit.max, defaultDeposit.max),
        deposit_max_commission_currency: withBackup(deposit.maxCurrency, defaultDeposit.maxCurrency),

        cashout_commission_percent: withBackup(cashout.percent, defaultCashout.percent),
        cashout_commission_fixed: withBackup(cashout.fixed, defaultCashout.fixed),
        cashout_commission_fixed_currency: withBackup(cashout.fixedCurrency, defaultCashout.fixedCurrency),
        cashout_min_commission: withBackup(cashout.min, defaultCashout.min),
        cashout_min_commission_currency: withBackup(cashout.minCurrency, defaultCashout.minCurrency),
        cashout_failed_commission_fixed: withBackup(cashout.failed, defaultCashout.failed),
        cashout_failed_commission_fixed_currency: withBackup(cashout.failedCurrency, defaultCashout.failedCurrency),
        cashout_max_commission: withBackup(cashout.max, defaultCashout.max),
        cashout_max_commission_currency: withBackup(cashout.maxCurrency, defaultCashout.maxCurrency),

        refund_commission_percent: withBackup(refund.percent, defaultRefund.percent),
        refund_commission_fixed: withBackup(refund.fixed, defaultRefund.fixed),
        refund_commission_fixed_currency: withBackup(refund.fixedCurrency, defaultRefund.fixedCurrency),
        refund_min_commission: withBackup(refund.min, defaultRefund.min),
        refund_min_commission_currency: withBackup(refund.minCurrency, defaultRefund.minCurrency),

        chargeback_commission_percent: withBackup(chargeback.percent, defaultChargeback.percent),
        chargeback_commission_fixed: withBackup(chargeback.fixed, defaultChargeback.fixed),
        chargeback_commission_fixed_currency: withBackup(chargeback.fixedCurrency, defaultChargeback.fixedCurrency),
        chargeback_min_commission: withBackup(chargeback.min, defaultChargeback.min),
        chargeback_min_commission_currency: withBackup(chargeback.minCurrency, defaultChargeback.minCurrency),
      };
    }),
  };

  return body;
});
