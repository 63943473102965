import type { Casino, PaymentSystem } from 'types/api';

export const DEFAULT_RESTRICTIONS = '{}';

export const DEFAULT_CASINO: Casino = {
  codename: 'default',
  id: 0,
  entity_name: '',
};

export const INITIAL_PAYMENT_SYSTEM: PaymentSystem = {
  id: 0,
  providerName: '',
  systemName: '',
  testMode: false,
  restrictions: DEFAULT_RESTRICTIONS,
  tags: {
    applied: [],
    excluded: [],
  },
  currencies: [],
  limits: {},
  protocols: {},
  casinos: [],
  hideSensitiveData: true,
  casino_settings: {
    [DEFAULT_CASINO.id]: {
      casino_id: DEFAULT_CASINO.id,
      payment_system_fee: 0,
      payment_system_fee_currency: '',
      payment_system_fee_percent: 0,
      access_data: {},
    },
  },
};

export const RESYNC_INTERVAL = 1000;
export const RETRY_COUNT = 5;
export const INCREASING_COEFFICIENT = 3;
export const RESYNC_LIMIT_COUNT = 30;

export const PROVIDERS_ALLOWING_MANUAL_CHILD_SYSTEM_ENTRY = ['finteqhub_seamless'];
export const PROVIDERS_WITH_DEFAULT_ACCESS_DATA = ['accentpay'];
