import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

export const DefaultLimitHint = styled(Typography)`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
`;

export const LimitsHeader = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.panel};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 500;
`;

export const LimitsHeaderItem = styled(Box)`
  padding: 10px 8px;
  border-left: 1px solid ${({ theme }) => theme.palette.background.default};

  &:nth-last-child(1) {
    border-right: 1px solid ${({ theme }) => theme.palette.background.default};
  }
`;

export const LimitTypeLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const LimitInputWrapper = styled(Box)`
  padding: 0 8px;
`;

export const LimitsRow = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.background.style2};

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.background.form};
  }
`;
