import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSnapshotAction } from 'api/snapshots';
import { snapshotBodySelector, snapshotFormSelector } from 'store/snapshot-form/selectors';
import { setSnapshotTitleAction, setSnapshotDataAction } from 'store/snapshot-form';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Dropzone from 'components/atoms/Dropzone';

const CasinoSnapshotsForm = () => {
  const dispatch = useDispatch();

  const { title, data } = useSelector(snapshotFormSelector);
  const saveRequestBody = useSelector(snapshotBodySelector);
  const onSaveClick = useCallback(() => {
    setInputFileKey(Date.now()); // clear FileInput by rerendering it
    dispatch(saveSnapshotAction(saveRequestBody));
  }, [saveRequestBody, dispatch]);
  const [inputFileKey, setInputFileKey] = useState(Date.now());

  const onDrop = useCallback(
    (fileList) => {
      if (fileList && fileList.length) {
        const file = fileList[0];
        dispatch(setSnapshotTitleAction(file.name));

        var reader = new FileReader();

        reader.onload = function (event) {
          let fileContent = event.target.result;
          // you can uncomment next lines to see fileContent object in console
          // let jsonObj = JSON.parse(fileContent);
          // console.log(jsonObj);

          dispatch(setSnapshotDataAction(fileContent));
        };

        reader.readAsText(file);
      } else {
        dispatch(setSnapshotTitleAction(''));
        dispatch(setSnapshotDataAction(''));
      }
    },
    [dispatch],
  );
  const onChangeTitle = useCallback((event) => dispatch(setSnapshotTitleAction(event.target.value)), [dispatch]);
  const onChangeData = useCallback((event) => dispatch(setSnapshotDataAction(event.target.value)), [dispatch]);
  return (
    <>
      <Typography variant="h6">Create snapshot</Typography>
      <Dropzone accept=".json" onDrop={onDrop} />
      <TextField label="Title" fullWidth value={title} onChange={onChangeTitle} sx={{marginBottom:2, marginTop:4}} />
      <TextField
        label="Data (JSON)"
        value={data}
        fullWidth
        onChange={onChangeData}
        inputProps={{
          minRows: 5,
          maxRows: 20,
        }}
        InputProps={{
          inputComponent: TextareaAutosize,
        }}
        sx={{marginBottom:2, marginTop:2}}
      />

      <div>
        <Button variant="outlined" onClick={onSaveClick}>
          Save
        </Button>
      </div>
    </>
  );
};

export default memo(CasinoSnapshotsForm);
