import React from 'react';
import type { FC, ReactNode } from 'react';

import { BadgeVariants } from './Badge.constants';
import { Wrapper } from './Badge.styles';

interface BadgeProps {
  variant: BadgeVariants;
  children: ReactNode;
}

const Badge: FC<BadgeProps> = ({ variant = BadgeVariants.SUCCESS, children }) => {
  return <Wrapper variant={variant}>{children}</Wrapper>;
};

export default Badge;
