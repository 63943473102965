import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const createCasinoSnapshots = (casinoSnapshots) =>
  axiosInstance.post(ENDPOINTS.CREATE_CASINO_SNAPSHOTS, casinoSnapshots);

export const {
  doRequestAction: createCasinoSnapshotsAction,
  requestFulfilledAction: createCasinoSnapshotsFulfilledAction,
  requestRejectedAction: createCasinoSnapshotsRejectedAction,
  errorSelector: createCasinoSnapshotsErrorSelector,
} = requestsFactory({
  request: createCasinoSnapshots,
  stateRequestKey: 'casino_snapshots__post',
});
