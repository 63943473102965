import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Snapshots from 'components/organisms/Snapshots';
import SnapshotForm from 'components/organisms/SnapshotForm';

export default function () {
  return (
    <Routes>
      <Route index element={<Snapshots />} />
      <Route path="add" element={<SnapshotForm />} />
    </Routes>
  );
}
