import type { InputBaseComponentProps } from '@mui/material';
import React, { forwardRef } from 'react';
import type { IMask } from 'react-imask';
import { IMaskInput } from 'react-imask';

interface MaskedInputV2Props extends Omit<InputBaseComponentProps, 'onChange'> {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: typeof IMask.Masked;
  min?: number;
  radix?: string;
  scale?: number;
  normalizeZeros?: boolean;
  value: string;
}

const MaskedInputV2 = forwardRef<HTMLInputElement, MaskedInputV2Props>(function TextMaskCustom(props, ref) {
  const { onChange, value, ...other } = props;

  return (
    <IMaskInput
      {...other}
      unmask="typed"
      inputRef={ref}
      value={value}
      onAccept={(newValue) => {
        if (value !== newValue) {
          onChange({ target: { name: props.name, value: newValue } });
        }
      }}
      overwrite
    />
  );
});

export default MaskedInputV2;
