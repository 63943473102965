import styled from 'styled-components';
import Container from '@mui/material/Container';

export const AppWrapper = styled(Container)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 0;
  min-height: 100vh;
`;

export const PageWrapper = styled(Container)`
  padding: ${({ theme }) => theme.spacing(1, 0)};
`;
