import styled from 'styled-components';
import Toolbar from '@mui/material/Toolbar';
import NavLink from 'components/atoms/NavLink';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledAppBar = styled(AppBar)`
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(78, 121, 135, 0.14);
`;

export const StyledToolbar = styled(Toolbar)`
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 10px;
`;

export const NavMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;
`;

export const NavMenuItem = styled(NavLink)`
  padding: 12px 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  height: 48px;
  box-sizing: border-box;

  &.active {
    color: ${({ theme }) => theme.palette.primary.main};
    border-bottom: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`;
