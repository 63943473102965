import styled from 'styled-components';
import Pagination from '@mui/material/Pagination';

export const StyledPagination = styled(Pagination)`
  & .MuiPagination-ul {
    gap: 6px;
  }

  & .MuiPaginationItem-root {
    color: ${({ theme }) => theme.palette.text.secondary};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  & .MuiPaginationItem-root.Mui-selected {
    color: ${({ theme }) => theme.palette.text.secondary};
    background-color: ${({ theme }) => theme.palette.background.style2};
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
`;
