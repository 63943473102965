import { MAX_SCALE, PERCENT_SCALE } from 'constants/scales';
import { DEFAULT_CASINO } from 'constants/paymentSystem';

import React, { useEffect, type FC } from 'react';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import type { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { type Currency, type PaymentSystem } from 'types/api';
import FormSelect from 'components/atoms/FormSelect';
import NumberInput from 'components/atoms/NumberInput';

interface FeeFieldsProps {
  showPaymentFees: boolean;
  showPaymentFeePercent: boolean;
  casinoId: number;
  currencies: Currency[];
  errors: FieldErrors<PaymentSystem>;
  control: Control<PaymentSystem>;
  watch: UseFormWatch<PaymentSystem>;
  onValidateFees: (casinoId: number, fieldName: string) => void;
}

const FeeFields: FC<FeeFieldsProps> = ({
  showPaymentFees,
  showPaymentFeePercent,
  casinoId,
  currencies,
  errors,
  control,
  watch,
  onValidateFees,
}) => {
  const selectedCurrency = watch(`casino_settings.${casinoId}.payment_system_fee_currency`);
  const feeAmount = watch(`casino_settings.${casinoId}.payment_system_fee`);

  useEffect(() => {
    onValidateFees(casinoId, 'payment_system_fee_currency');
  }, [feeAmount, casinoId, onValidateFees]);

  useEffect(() => {
    onValidateFees(casinoId, 'payment_system_fee');
  }, [selectedCurrency, casinoId, onValidateFees]);

  const isDefaultCasino = casinoId === DEFAULT_CASINO.id;
  const currenciesOptions = currencies.map((c) => ({ value: c.iso_code, label: c.iso_code }));
  let scale = 0;

  if (showPaymentFees) {
    scale = currencies.find((c) => c.iso_code === selectedCurrency)?.digits_after_comma ?? MAX_SCALE;
  } else if (showPaymentFeePercent) {
    scale = PERCENT_SCALE;
  }

  return (
    <>
      {showPaymentFees && (
        <>
          <Grid item width="calc(50% - 8px)" pt={3}>
            <Controller
              name={`casino_settings.${casinoId}.payment_system_fee`}
              control={control}
              render={({ field }) => (
                <NumberInput
                  {...field}
                  label="Payment system fee"
                  required={!!selectedCurrency && !isDefaultCasino}
                  scale={scale}
                  error={errors.casino_settings?.[casinoId]?.payment_system_fee}
                />
              )}
            />
          </Grid>
          <Grid item width="calc(50% - 8px)" pt={3}>
            <Controller
              name={`casino_settings.${casinoId}.payment_system_fee_currency`}
              control={control}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label="Payment system fee currency"
                  required={!!feeAmount && !isDefaultCasino}
                  options={currenciesOptions}
                  error={errors.casino_settings?.[casinoId]?.payment_system_fee_currency}
                  showBlankValue
                  blankValue=""
                />
              )}
            />
          </Grid>
        </>
      )}
      {showPaymentFeePercent && (
        <Grid item width="calc(50% - 8px)" pt={3}>
          <Controller
            name={`casino_settings.${casinoId}.payment_system_fee_percent`}
            control={control}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Payment system fee percent"
                required={!isDefaultCasino}
                scale={scale}
                error={errors.casino_settings?.[casinoId]?.payment_system_fee_percent}
              />
            )}
          />
        </Grid>
      )}
    </>
  );
};

export default FeeFields;
