import { createSelector } from 'reselect';
import { casinosResponseSelector } from 'api/casinos';
import map from 'ramda/src/map';

export const casinoSnapshotsFormSelector = (state) => state.casinoSnapshotsForm;

export const casinoSnapshotsBodySelector = createSelector([casinoSnapshotsFormSelector], (snapshotForm) => {
  const { snapshotId, casinoIds, casinosAccessData } = snapshotForm;

  const body = {
    snapshot_id: snapshotId,
    casino_ids: casinoIds.map(Number),
    casinos_access_data: casinosAccessData,
  };

  return body;
});

export const casinosOptionsSelector = createSelector(
  casinosResponseSelector,
  map((casino) => {
    casino.id = String(casino.id);
    return casino;
  }),
);