import styled from 'styled-components';

import { BadgeVariants } from './Badge.constants';

interface WrapperProps {
  variant: BadgeVariants;
}

export const Wrapper = styled.span<WrapperProps>`
  background-color: ${({ variant, theme }) => {
    switch (variant) {
      case BadgeVariants.SUCCESS:
        return theme.palette.success.main;
      case BadgeVariants.ERROR:
        return theme.palette.error.main;
      case BadgeVariants.WARNING:
        return theme.palette.warning.main;
    }
  }};
  display: inline-flex;
  height: 14px;
  padding: 0 3px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.common.white};
  text-transform: uppercase;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
`;
