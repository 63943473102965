import styled from 'styled-components';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';

export const TagsWrapper = styled(Box)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  max-width: 100%;
`;

export const StyledListSubheader = styled(ListSubheader)`
  padding: 8px 12px;

  & .Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.background.style2};
    border-radius: 4px;
  },
`;

export const StyledMenuItem = styled(MenuItem)`
  height: 38px;
  padding: 0;
`;

export const StyledListItemText = styled(ListItemText)`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const StyledSelect = styled(Select)`` as typeof Select;

export const StyledFormControl = styled(FormControl)`
  width: 160px;

  & .Mui-focused .MuiOutlinedInput-notchedOutline,
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.background.style2};
    border-radius: 4px;
  }

  & .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.palette.text.dark};
`;
