export enum PaymentSystemSearchParam {
  Page = 'page',
  Sorting = 's',
  SortField = 'sort_field',
  SortDirection = 'sort_dir',
  SystemName = 'q[system_name_in]',
  ChildSystemName = 'q[child_system_name_in]',
  Status = 'q[status_in]',
  Currencies = 'q[available_currencies_cont_any]',
  Tags = 'q[tags_cont_array]',
}

export const paymentSystemFilterKeys = {
  systemName: PaymentSystemSearchParam.SystemName,
  childSystem: PaymentSystemSearchParam.ChildSystemName,
  status: PaymentSystemSearchParam.Status,
  tags: PaymentSystemSearchParam.Tags,
  currencies: PaymentSystemSearchParam.Currencies,
};

export type PaymentSystemFilterKey = keyof typeof paymentSystemFilterKeys;

const propertyToSortFieldMap: Record<string, string> = {
  casinoName: 'casino_name',
  systemName: 'system_name',
  childSystem: 'child_system_name',
  status: 'status',
};

export const getSortFieldByPropertyName = (key: string): string => {
  return propertyToSortFieldMap[key];
};
