import { Grid } from '@mui/material';
import FormDivider from 'components/atoms/FormDivider';
import FormSelect from 'components/atoms/FormSelect';
import React from 'react';
import type { FC } from 'react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { PaymentSystem } from 'types/api';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';

import { FormSectionTitle } from '../../PaymentSystemForm.styles';

interface CashflowSectionProps {
  control: Control<PaymentSystem>;
  cashflows: FormSelectOption[];
}

export const CashflowSection: FC<CashflowSectionProps> = ({ control, cashflows }) => {
  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle>Cashflow</FormSectionTitle>
      </Grid>

      <Grid item xs={10}>
        <Controller
          name="cashflowId"
          control={control}
          render={({ field }) => <FormSelect label="Cashflow" {...field} options={cashflows} showBlankValue={true} />}
        />
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
