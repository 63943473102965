import { CircularProgress } from '@mui/material';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { isSomethingLoadingSelector } from 'redux-requests-factory';

import { StyledBackdrop } from './GlobalLoading.styled';

export const GlobalLoading = () => {
  const isSomethingLoading = useSelector(isSomethingLoadingSelector);

  return (
    <StyledBackdrop open={isSomethingLoading}>
      <CircularProgress />
    </StyledBackdrop>
  );
};

export default memo(GlobalLoading);
