import {
  type PaymentSystemFilterKey,
  PaymentSystemSearchParam,
  getSortFieldByPropertyName,
  paymentSystemFilterKeys,
} from 'constants/paymentSystemSearchParam';

import type { GridSortModel } from '@mui/x-data-grid';
import type { GetPaymentSystemsRequestParams } from 'api/payment-systems';

export const getPaymentSystemsFilters = (searchParams: URLSearchParams): Record<string, string[]> => {
  const filters: Record<string, string[]> = {};

  Object.keys(paymentSystemFilterKeys).forEach((key) => {
    const filterKey = key as PaymentSystemFilterKey;
    const filterValue = searchParams.get(paymentSystemFilterKeys[filterKey]);

    if (filterValue) {
      filters[filterKey] = filterValue.split(',');
    }
  });

  return filters;
};

export const getPaymentSystemsSortModel = (searchParams: URLSearchParams): GridSortModel => {
  const sortModel: GridSortModel = [];

  const sortField = searchParams.get(PaymentSystemSearchParam.SortField) || '';
  const sortDirection = searchParams.get(PaymentSystemSearchParam.SortDirection) || '';

  if (sortField && (sortDirection === 'asc' || sortDirection === 'desc')) {
    sortModel.push({ field: sortField, sort: sortDirection });
  }

  return sortModel;
};

export const getPaymentSystemsRequestParams = (searchParams: URLSearchParams): GetPaymentSystemsRequestParams => {
  const sortField = searchParams.get(PaymentSystemSearchParam.SortField);
  const sortDirection = searchParams.get(PaymentSystemSearchParam.SortDirection);

  const sorting = sortField && sortDirection ? `${getSortFieldByPropertyName(sortField)} ${sortDirection}` : '';
  const page = searchParams.get(PaymentSystemSearchParam.Page);

  const params: Record<string, string> = {};

  Object.keys(paymentSystemFilterKeys).forEach((key) => {
    const filterKey = key as PaymentSystemFilterKey;
    const filterValue = searchParams.get(paymentSystemFilterKeys[filterKey]);

    if (filterValue) {
      params[paymentSystemFilterKeys[filterKey]] = filterValue;
    }
  });

  if (sorting) {
    params[PaymentSystemSearchParam.Sorting] = sorting;
  }

  if (page) {
    params[PaymentSystemSearchParam.Page] = page;
  }

  return params;
};
