import type { AxiosError, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import identity from 'ramda/src/identity';
import path from 'ramda/src/path';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

const handleError = (error: AxiosError) => {
  if (error?.response?.status === 403) {
    document.location = `${process.env.REACT_APP_BACKEND_URL}/users/auth/google_oauth2`;
  }
  return Promise.reject(path(['response', 'data'], error));
};

instance.interceptors.response.use(identity, handleError);

const api = {
  get: <P, R>(url: string, config: AxiosRequestConfig = {}, params?: P): Promise<R> =>
    instance
      .get(url, {
        params,
        responseType: 'json',
        ...config,
      })
      .then(({ data }) => data),
  post: <D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> =>
    instance.post(url, data, config).then(({ data }) => data),
  patch: <D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> =>
    instance.patch(url, data, config).then(({ data }) => data),
  put: <D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> =>
    instance.put(url, data, config).then(({ data }) => data),
  delete: <D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> =>
    instance.delete(url, { ...config, data }).then(({ data }) => data),
};

export default api;
