import { requestsFactory } from 'redux-requests-factory';
import type { CurrenciesResposne } from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getCurrencies = (): Promise<CurrenciesResposne> => axiosInstance.get(ENDPOINTS.CURRENCIES);

export const { loadDataAction: loadCurrenciesAction, responseSelector: currenciesResponseSelector } = requestsFactory({
  request: getCurrencies,
  stateRequestKey: 'currencies',
  transformResponse: (response?: CurrenciesResposne) => response ?? { currencies: [], protocols: [] },
});

const getFilterCurrencies = (): Promise<string[]> => axiosInstance.get(ENDPOINTS.FILTER_CURRENCIES);

export const { loadDataAction: loadFilterCurrencies, responseSelector: filterCurrenciesResponseSelector } =
  requestsFactory({
    request: getFilterCurrencies,
    stateRequestKey: 'filter-currencies',
    transformResponse: (response?: string[]) => response ?? [],
    includeInGlobalLoading: false,
  });
