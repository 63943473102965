import { Box } from '@mui/material';
import React, { type FC, type PropsWithChildren } from 'react';

interface TabPanelProps {
  value: number;
  index: number;
}

export const TabPanel: FC<PropsWithChildren<TabPanelProps>> = ({ children, value, index, ...props }) => {
  return (
    <div role="tabpanel" hidden={value !== index} {...props}>
      {value === index && (
        <Box display="flex" flexWrap="wrap">
          {children}
        </Box>
      )}
    </div>
  );
};
