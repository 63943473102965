import styled from 'styled-components';
import Typography from '@mui/material/Typography';

export const DropZoneWrapper = styled.div`
  cursor: pointer;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed #cccccc;

  &:hover {
    border-color: #1976d2;
  }
`;

export const DropZoneText = styled(Typography)`
  display: inline-block;
  margin-bottom: 15px;
`;
