import React, { forwardRef } from 'react';
import type { FC } from 'react';
import type { FormTextFieldProps } from 'components/atoms/FormTextField';
import FormTextField from 'components/atoms/FormTextField';
import MaskedInputV2 from 'components/atoms/MaskedInputV2';
import type { FieldError } from 'react-hook-form';

import ValidationTooltip from '../ValidationTooltip';

interface NumberInputProps extends FormTextFieldProps {
  scale?: number;
  mask?: RegExp;
  error?: FieldError;
  showValidationTooltip?: boolean;
}

const NumberInput: FC<NumberInputProps> = forwardRef(
  ({ scale, mask, error, showValidationTooltip = false, ...props }, ref) => {
    return (
      <ValidationTooltip errorMessage={showValidationTooltip ? error?.message : ''}>
        <FormTextField
          {...props}
          inputProps={{
            mask: mask || Number,
            radix: '.',
            min: 0,
            scale,
          }}
          error={error}
          showError={!showValidationTooltip}
          ref={ref}
          InputProps={{
            inputComponent: MaskedInputV2,
          }}
        />
      </ValidationTooltip>
    );
  },
);

export default NumberInput;
