import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.text.secondary};
  color: ${({ theme }) => theme.palette.text.white};

  &:hover {
    background-color: ${({ theme }) => theme.palette.text.secondary};
    opacity: 0.9;
  }
`;
