import { PaymentSystemStatus } from 'constants/paymentSystemStatus';

import type { FC } from 'react';
import React from 'react';
import Badge, { BadgeVariants } from 'components/atoms/Badge';
import { getStatusText } from 'utils/paymentSystemStatusHelper';

interface StatusBadgeProps {
  status: PaymentSystemStatus;
  hint: string;
}

const getBadgeVariant = (status: PaymentSystemStatus): BadgeVariants => {
  switch (status) {
    case PaymentSystemStatus.SYNCED:
      return BadgeVariants.SUCCESS;
    case PaymentSystemStatus.NON_SYNCED:
      return BadgeVariants.ERROR;
    case PaymentSystemStatus.SYNC_RETRYING:
      return BadgeVariants.WARNING;
  }
};

const StatusBadge: FC<StatusBadgeProps> = ({ status }) => {
  return (
    <>
      <Badge variant={getBadgeVariant(status)}>{getStatusText(status)}</Badge>
    </>
  );
};

export default StatusBadge;
