import React from 'react';
import type { FC, MouseEventHandler } from 'react';
import type { DrawerProps as MuiDrawerProps } from '@mui/material';
import { Close } from '@mui/icons-material';

import {
  DrawerAppBar,
  DrawerContent,
  DrawerIconButton,
  DrawerTitle,
  DrawerToolbar,
  StyledDrawer,
} from './Drawer.styles';

interface DrawerProps extends MuiDrawerProps {
  title: string;
}

const Drawer: FC<DrawerProps> = ({ open, anchor = 'right', onClose, children, title }) => {
  const onCloseClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (onClose) {
      onClose(event, 'backdropClick');
    }
  };

  return (
    <StyledDrawer anchor={anchor} open={open} onClose={onClose}>
      <DrawerAppBar position="sticky">
        <DrawerToolbar variant="dense">
          <DrawerIconButton onClick={onCloseClick}>
            <Close />
          </DrawerIconButton>
          <DrawerTitle>{title}</DrawerTitle>
        </DrawerToolbar>
      </DrawerAppBar>
      <DrawerContent>{children}</DrawerContent>
    </StyledDrawer>
  );
};

export default Drawer;
