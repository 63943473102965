import { createActions, handleActions } from 'redux-actions';
import { noop } from 'rxjs';
import identity from 'ramda/src/identity';
import max from 'ramda/src/max';
import reduce from 'ramda/src/reduce';
import keys from 'ramda/src/keys';
import omit from 'ramda/src/omit';

const operationCommission = {
  percent: '',
  fixed: '',
  fixedCurrency: null,
  min: '',
  minCurrency: null,
};

const failedCommission = {
  failed: '',
  failedCurrency: null,
};

const maxCommission = {
  max: '',
  maxCurrency: null,
};

const initialServiceCommission = {
  serviceName: '',
  deposit: {
    ...operationCommission,
    ...failedCommission,
    ...maxCommission,
  },
  cashout: {
    ...operationCommission,
    ...failedCommission,
    ...maxCommission,
  },
  refund: {
    ...operationCommission,
  },
  chargeback: {
    ...operationCommission,
  },
};

const DEFAULT_STATE = {
  name: '',
  currency: null,
  acceptableCurrencies: [],
  commissions: {
    default: initialServiceCommission,
  },
};

export const {
  initCashflowFormAction,
  setNameAction,
  setCurrencyAction,
  setAcceptableCurrenciesAction,
  addServiceAction,
  deleteServiceAction,
  setServiceNameAction,
  setServiceCommissionAction,
  resetCashflowFormAction,
} = createActions(
  {
    INIT_CASHFLOW_FORM_ACTION: noop,
    SET_NAME_ACTION: identity,
    SET_CURRENCY_ACTION: identity,
    SET_ACCEPTABLE_CURRENCIES_ACTION: identity,
    ADD_SERVICE_ACTION: noop,
    DELETE_SERVICE_ACTION: identity,
    SET_SERVICE_NAME_ACTION: identity,
    SET_SERVICE_COMMISSION_ACTION: identity,
    RESET_CASHFLOW_FORM_ACTION: noop,
  },
  {
    prefix: 'CASHFLOW_FORM',
  },
);

export const cashflowFormReducer = handleActions(
  {
    [setNameAction]: (state, { payload }) => ({ ...state, name: payload }),
    [setCurrencyAction]: (state, { payload }) => ({ ...state, currency: payload }),
    [setAcceptableCurrenciesAction]: (state, { payload }) => ({ ...state, acceptableCurrencies: payload }),
    [addServiceAction]: (state) => {
      const { default: defaultService, ...otherServices } = state.commissions;
      const servicesIds = keys(otherServices).map((i) => Number.parseInt(i, 10));
      const newServiceIndex = reduce(max, 0, servicesIds) + 1;

      return { ...state, commissions: { ...state.commissions, [newServiceIndex]: initialServiceCommission } };
    },
    [deleteServiceAction]: (state, { payload }) => ({
      ...state,
      commissions: { ...omit([payload], state.commissions) },
    }),
    [setServiceNameAction]: (state, { payload }) => ({
      ...state,
      commissions: {
        ...state.commissions,
        [payload.index]: {
          ...state.commissions[payload.index],
          serviceName: payload.name,
        },
      },
    }),
    [setServiceCommissionAction]: (state, { payload }) => ({
      ...state,
      commissions: {
        ...state.commissions,
        [payload.index]: {
          ...state.commissions[payload.index],
          [payload.type]: {
            ...state.commissions[payload.index][payload.type],
            [payload.field]: payload.value,
          },
        },
      },
    }),
    [resetCashflowFormAction]: () => ({ ...DEFAULT_STATE }),
  },
  DEFAULT_STATE,
);
