export enum FieldType {
  String = 'string',
  Integer = 'integer',
  Boolean = 'boolean',
  Array = 'array',
}

export interface AccessDataField {
  name: string;
  type: FieldType;
  required: boolean;
  requiredIf: string[];
  isRsaKey: boolean;
  label: string;
  enum?: string[];
  default_value?: string;
  items?: {
    type: FieldType;
    enum: string[];
  };
}

export interface ExistingAccessData {
  id: number;
  casino_id: number;
  access_data: {
    [key: string]: unknown;
  };
}

export interface AccessData {
  fields: AccessDataField[];
  existing: ExistingAccessData[];
}

export const getDefaultValueByFieldType = (type: FieldType) => {
  switch (type) {
    case FieldType.String:
      return '';
    case FieldType.Array:
      return [];
    case FieldType.Boolean:
      return false;
    default:
      return null;
  }
};
