import React from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';

import { DropZoneWrapper, DropZoneText } from './Dropzone.styles';

export default function ({ accept, onDrop }) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept });

  return (
    <DropZoneWrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <DropZoneText>Drag n drop some files here</DropZoneText>
      <Button variant="outlined">Browse</Button>
    </DropZoneWrapper>
  );
}
