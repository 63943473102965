import { NOTIFICATION_DURATION } from 'constants/notifications';

import { Alert, Snackbar, type AlertColor } from '@mui/material';
import React from 'react';
import type { FC, PropsWithChildren } from 'react';

interface NotificationBarProps {
  isOpen: boolean;
  type: AlertColor;
  onClose: () => void;
}

const NotificationBar: FC<PropsWithChildren<NotificationBarProps>> = ({ isOpen, type, children, onClose }) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={NOTIFICATION_DURATION}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={onClose}
    >
      <Alert severity={type} onClose={onClose}>
        {children}
      </Alert>
    </Snackbar>
  );
};

export default NotificationBar;
