import { IconName } from 'constants/iconName';

import React, { useState } from 'react';
import type { FC } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { IconButton, Tooltip } from '@mui/material';
import type { FilterSelectOption } from 'components/atoms/FilterSelect';
import FilterSelect from 'components/atoms/FilterSelect';
import SvgIcon from 'components/atoms/SvgIcon';

import { Wrapper } from './FiltersBar.styles';

interface FilterConfig {
  id: string;
  label: string;
  options: FilterSelectOption[];
}

interface FiltersBarProps {
  filters: Record<string, FilterConfig>;
  defaultFilters: Record<string, string[]>;
  onSubmit: (result: Record<string, string[]>) => void;
  onResetFilters: () => void;
}

const FiltersBar: FC<FiltersBarProps> = ({ filters, defaultFilters, onSubmit, onResetFilters }) => {
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string[]>>(defaultFilters);

  const handleFilter = () => {
    const result = Object.keys(filters).reduce<Record<string, string[]>>(
      (acc, key) => ({
        ...acc,
        [key]: selectedFilters[key] || [],
      }),
      {},
    );
    onSubmit(result);
  };

  const handleReset = () => {
    setSelectedFilters({});
    onResetFilters();
  };

  return (
    <Wrapper>
      <Box display="flex" gap="16px" alignItems="center">
        {Object.keys(filters).map((key) => {
          const filterConfig = filters[key];
          return (
            <FilterSelect
              key={filterConfig.id}
              options={filterConfig.options}
              selectedOptions={selectedFilters[key] || []}
              onChange={(filters) => {
                setSelectedFilters((prevState) => ({
                  ...prevState,
                  [key]: filters,
                }));
              }}
              id={filterConfig.id}
              label={filterConfig.label}
            />
          );
        })}
        <Box ml="auto" display="flex" alignItems="center" gap="16px">
          <Button variant="outlined" onClick={handleFilter}>
            Filter
          </Button>
          <Tooltip arrow title="Reset filters">
            <IconButton onClick={handleReset}>
              <SvgIcon name={IconName.ResetFilters} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default FiltersBar;
