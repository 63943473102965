import React, { useState } from 'react';
import type { FC } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import type { MenuProps } from '@mui/material';
import { OutlinedInput } from '@mui/material';

import {
  TagsWrapper,
  StyledListSubheader,
  StyledMenuItem,
  StyledListItemText,
  StyledSelect,
  StyledFormControl,
  StyledChip,
} from './FilterSelect.styles';

export interface FilterSelectOption {
  label: string;
  value: string;
}

interface FilterSelectProps {
  options: FilterSelectOption[];
  selectedOptions: string[];
  onChange: (selectedOptions: string[]) => void;
  id: string;
  label: string;
}

const menuProps: Partial<MenuProps> = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: 320,
      width: 220,
    },
  },
  MenuListProps: {
    style: {
      paddingTop: 0,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterSelect: FC<FilterSelectProps> = ({ options, selectedOptions, onChange, id, label }) => {
  const [searchText, setSearchText] = useState('');

  const displayedOptions = options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

  const renderValue = (selected: string[]) => {
    if (selectedOptions.length === options.length || selectedOptions.length === 0) {
      return 'All';
    }

    const sortedSelected = [...selected].sort();

    return (
      <TagsWrapper>
        <StyledChip
          onMouseDown={(event) => event.stopPropagation()}
          onDelete={() => {
            onChange(selectedOptions.filter((option) => option !== sortedSelected[0]));
          }}
          variant="outlined"
          sx={{ maxWidth: selectedOptions.length > 1 ? '75px' : '100%' }}
          size="small"
          label={options.find((o) => o.value === sortedSelected[0])?.label}
        />
        {selected.length > 1 && (
          <StyledChip variant="outlined" size="small" label={`+${selected.length - 1}`}></StyledChip>
        )}
      </TagsWrapper>
    );
  };

  return (
    <StyledFormControl size="small">
      <InputLabel size="small" shrink id={`search-select-label-${id}`}>
        {label}
      </InputLabel>
      <StyledSelect
        multiple
        notched
        displayEmpty
        MenuProps={menuProps}
        labelId={`search-select-label-${id}`}
        id={`search-select-${id}`}
        value={selectedOptions}
        input={<OutlinedInput label={label} />}
        renderValue={renderValue}
        onChange={(event) => {
          const {
            target: { value: selectedValue },
          } = event;

          const value = typeof selectedValue === 'string' ? selectedValue.split(',') : selectedValue;

          if (value[value.length - 1] === 'select-all') {
            if (selectedOptions.length < options.length) {
              onChange(options.map((option) => option.value));
            } else if (selectedOptions.length === options.length) {
              onChange([]);
            }
          } else {
            onChange(value);
          }
        }}
        onClose={() => setSearchText('')}
      >
        <StyledListSubheader>
          <TextField
            size="small"
            autoFocus
            placeholder="Type to search..."
            fullWidth
            onChange={(event) => setSearchText(event.target.value)}
            onKeyDown={(event) => {
              if (event.key !== 'Escape') {
                event.stopPropagation();
              }
            }}
          />
        </StyledListSubheader>
        {searchText.length === 0 && (
          <StyledMenuItem value="select-all">
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selectedOptions.length === options.length} />
            <StyledListItemText
              primaryTypographyProps={{
                variant: 'body1',
              }}
            >
              All
            </StyledListItemText>
          </StyledMenuItem>
        )}
        {displayedOptions.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selectedOptions.includes(option.value)} />
            <StyledListItemText
              primaryTypographyProps={{
                variant: 'body1',
              }}
            >
              {option.label}
            </StyledListItemText>
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default FilterSelect;
