import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemText from '@mui/material/ListItemText';
import identity from 'ramda/src/identity';
import differenceWith from 'ramda/src/differenceWith';
import equals from 'ramda/src/equals';
import prop from 'ramda/src/prop';
import unionWith from 'ramda/src/unionWith';
import includes from 'ramda/src/includes';

import { StyledListSubheader, StyledSelect, StyledChip, EmptyState } from './Select.styles';

const buildGroupedItem = (groupedItems, renderItems, onToggleGroup, selectedItems) => {
  return Object.entries(groupedItems).reduce((acc, [key, options = []]) => {
    const isChecked = options.every(({ value }) => includes(value, selectedItems));
    return acc.concat(
      <StyledListSubheader disableSticky>
        <FormControlLabel control={<Checkbox checked={isChecked} onChange={onToggleGroup} name={key} />} label={key} />
      </StyledListSubheader>,
      ...options.map(renderItems),
    );
  }, []);
};

export default function ({
  multiple,
  value: selectedItems,
  onChange,
  options,
  multipleLabelGetter = identity,
  valueGetter = identity,
  labelGetter = identity,
  groupWith,
  emptyState,
  ...props
}) {
  const popoverAction = useRef();
  const elementRef = useRef();
  const defaultValue = useMemo(() => (multiple ? [] : ''), [multiple]);
  const onChangeValue = useCallback(
    (event) => {
      const value = Array.isArray(event.target.value)
        ? event.target.value.filter((value) => !!value)
        : event.target.value;

      onChange(value, event);
    },
    [onChange],
  );

  useEffect(() => {
    if (elementRef.current) {
      const { innerHeight } = window;
      const { bottom } = elementRef.current.getBoundingClientRect();

      if (bottom >= innerHeight) {
        document.documentElement.scrollTop += bottom - innerHeight + 40;
      }
    }

    if (popoverAction.current) {
      popoverAction.current.updatePosition();
    }
  }, [selectedItems]);

  const onDeleteOption = useCallback(
    (selectedItem) => () => {
      if (onChange) {
        onChange(selectedItems.filter((item) => !equals(item, selectedItem)));
      }
    },
    [selectedItems, onChange],
  );

  const onMouseDownOption = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const preparedOptions = useMemo(() => {
    const formattedOptions = options.map((option) => ({
      value: valueGetter(option),
      label: labelGetter(option),
      origin: option,
    }));

    if (typeof groupWith === 'function') {
      return groupWith(formattedOptions);
    }

    return formattedOptions;
  }, [valueGetter, labelGetter, groupWith, options]);

  const renderValue = useMemo(
    () =>
      multiple
        ? (selectedItems) =>
            selectedItems.map((selectedItem) => {
              const label = multipleLabelGetter(selectedItem);

              return (
                <StyledChip
                  key={label}
                  label={label}
                  onDelete={onDeleteOption(selectedItem)}
                  onMouseDown={onMouseDownOption}
                />
              );
            })
        : undefined,
    [multiple, multipleLabelGetter, onDeleteOption, onMouseDownOption],
  );

  const renderItems = useCallback(
    (option) => {
      const key = typeof option.value === 'object' && option.value.id ? option.value.id : option.value;

      return (
        <MenuItem key={key} value={option.value}>
          {multiple ? <Checkbox checked={includes(option.value, selectedItems || defaultValue)} /> : null}
          <ListItemText primary={option.label} />
        </MenuItem>
      );
    },
    [selectedItems, multiple, defaultValue],
  );

  const menuProps = useMemo(
    () => ({
      action: popoverAction,
      PaperProps: multiple
        ? {
            style: {
              minWidth: 'none',
              maxWidth: 250,
              width: 250,
            },
          }
        : undefined,
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: multiple ? 'top' : 'bottom',
        horizontal: multiple ? 'right' : 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    }),
    [multiple],
  );

  const onToggleGroup = (event, checked) => {
    const optionsByGroup = preparedOptions[event.target.name];
    const operator = checked ? unionWith : differenceWith;

    onChange(operator(equals, selectedItems, optionsByGroup.map(prop('value'))));
  };

  const renderOptions = Array.isArray(preparedOptions)
    ? preparedOptions.map(renderItems)
    : buildGroupedItem(preparedOptions, renderItems, onToggleGroup, selectedItems);

  return (
    <StyledSelect
      ref={elementRef}
      multiple={multiple}
      value={selectedItems || defaultValue}
      onChange={onChangeValue}
      renderValue={renderValue}
      MenuProps={{
        ...menuProps,
        action: popoverAction,
      }}
      {...props}
    >
      {renderOptions.length > 0 ? renderOptions : <EmptyState align="center">{emptyState || 'No data'}</EmptyState>}
    </StyledSelect>
  );
}
