import React from 'react';
import type { PaginationItemProps } from '@mui/material/PaginationItem';
import PaginationItem from '@mui/material/PaginationItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  gridPageCountSelector,
  gridPageSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

import { StyledPagination } from './Pagination.styles';

const getRowRange = (page: number, rowCount: number, itemsPerPage = 50) => {
  const firstRowIndex = page * itemsPerPage + 1;
  const lastRowIndex = Math.min(firstRowIndex + itemsPerPage - 1, rowCount);

  return { firstRowIndex, lastRowIndex };
};

const Pagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const rowRange = getRowRange(page, rowCount);

  return (
    <>
      <StyledPagination
        showFirstButton
        showLastButton
        size="small"
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page + 1}
        count={pageCount}
        renderItem={(itemProps: PaginationItemProps) => <PaginationItem {...itemProps} />}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => apiRef.current.setPage(value - 1)}
      />
      <Box ml="40px">
        <Typography>
          {rowRange.firstRowIndex} - {rowRange.lastRowIndex} of {rowCount}
        </Typography>
      </Box>
    </>
  );
};

export default Pagination;
