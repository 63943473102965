import { FormHelperText, InputLabel } from '@mui/material';
import React, { forwardRef } from 'react';
import type { ControllerRenderProps, FieldError } from 'react-hook-form';

import { StyledSelect, StyledMenuItem, StyledFormControl } from './FormSelect.styles';

export interface FormSelectOption {
  label: string;
  value?: string | number;
}

interface SelectProps extends Omit<ControllerRenderProps, 'ref'> {
  label: string;
  options: FormSelectOption[];
  required?: boolean;
  disabled?: boolean;
  error?: FieldError;
  showBlankValue?: boolean;
  blankValue?: string;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxWidth: 'min-content',
    },
  },
};

const FormSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      value,
      options,
      error,
      required = false,
      disabled = false,
      showBlankValue = false,
      blankValue = undefined,
      ...props
    }: SelectProps,
    ref,
  ) => {
    const id = label.toLowerCase().replaceAll(' ', '-').concat('-id');

    return (
      <>
        <StyledFormControl size="small" required={required} disabled={disabled} error={!!error} fullWidth>
          <InputLabel id={id} shrink={true}>
            {label}
          </InputLabel>
          <StyledSelect
            {...props}
            ref={ref}
            notched
            labelId={id}
            label={label}
            value={value ?? ''}
            MenuProps={MenuProps}
          >
            {showBlankValue && <StyledMenuItem value={blankValue}></StyledMenuItem>}
            {options.length > 0 &&
              options.map(({ value, label }) => (
                <StyledMenuItem key={value} value={value}>
                  {label}
                </StyledMenuItem>
              ))}
          </StyledSelect>
        </StyledFormControl>
        {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
      </>
    );
  },
);

export default FormSelect;
