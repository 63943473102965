import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getCashflows = () => axiosInstance.get(ENDPOINTS.CASHFLOWS);

export const {
  loadDataAction: loadCashflowsAction,
  forcedLoadDataAction: forceLoadCashflowsAction,
  responseSelector: cashflowsResponseSelector,
} = requestsFactory({
  request: getCashflows,
  stateRequestKey: 'cashflows',
  transformResponse: (response) =>
    (response?.cashflows || [])
      .map((cashflow) => ({
        id: cashflow.id,
        name: cashflow.name,
        currency: cashflow.currency,
        acceptableCurrencies: cashflow.acceptable_currencies,
      }))
});

const saveCashflow = (cashflow) => axiosInstance.post(ENDPOINTS.SAVE_CASHFLOW, cashflow);

export const {
  doRequestAction: saveCashflowAction,
  requestFulfilledAction: saveCashflowFulfilledAction,
  requestRejectedAction: saveCashflowRejectedAction,
  errorSelector: saveCashflowErrorSelector,
} = requestsFactory({
  request: saveCashflow,
  stateRequestKey: 'cashflows__post',
  fulfilledActions: [() => forceLoadCashflowsAction()],
});
