import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@mui/material';

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &.Mui-disabled .MuiTypography-root {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.palette.text.primary};
  margin-left: 14px;

  &.Mui-disabled {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
