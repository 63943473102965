import styled from 'styled-components';
import { Button, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`;

export const StyledDialogContentText = styled(DialogContentText)`
  color: ${({ theme }) => theme.palette.text.dialogContent};
  padding: 8px 24px;
`;

export const HighlightedContentText = styled.span`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const WarningContentText = styled.span`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const StyledDialogButton = styled(Button)`
  padding: 6px 16px;
`;

export const StyledCancelButton = styled(StyledDialogButton)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
`;
