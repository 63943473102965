import React from 'react';
import type { FC, ReactNode } from 'react';
import { Dialog, DialogActions } from '@mui/material';

import {
  StyledDialogTitle,
  StyledDialogContentText,
  StyledDialogContent,
  StyledDialogButton,
  StyledCancelButton,
} from './ConfirmationDialog.styles';

interface ConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  children: ReactNode;
  onClose: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  isOpen,
  onClose,
  title,
  cancelButtonText,
  confirmButtonText,
  children,
  onCancel,
  onConfirm,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <StyledDialogContent>
        <StyledDialogContentText>{children}</StyledDialogContentText>
      </StyledDialogContent>
      <DialogActions>
        <StyledCancelButton onClick={onCancel}>{cancelButtonText}</StyledCancelButton>
        <StyledDialogButton onClick={onConfirm}>{confirmButtonText}</StyledDialogButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
