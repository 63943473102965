import styled from 'styled-components';
import ListSubheader from '@mui/material/ListSubheader';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

export const StyledChip = styled(Chip)`
  margin: 0 3px 3px 0;
`;

export const StyledListSubheader = styled(ListSubheader)`
  border-color: ${({ theme }) => {
    return '#cccccc';
  }};

  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;

  &:first-child {
    border-top: none;
  }
`;

export const EmptyState = styled(Typography)`
  opacity: 0.5;
`;

export const StyledSelect = styled(Select)`
  .MuiSelect-select {
    white-space: ${({ multiple }) => (multiple ? 'pre-wrap' : 'nowrap')};
  }
`;
