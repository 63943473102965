import { NUMBER_MASK } from 'constants/input';

import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setServiceNameAction, setServiceCommissionAction } from 'store/cashflow-form';
import keys from 'ramda/src/keys';
import omit from 'ramda/src/omit';
import has from 'ramda/src/has';
import { cashflowFormSelector } from 'store/cashflow-form/selectors';
import MaskedInput from 'components/atoms/MaskedInput';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import {
  FixedInputWrapper,
  TableCellMaskedInput,
  ComissionTabControl,
  CommissionSelect,
} from './CommissionsForm.styles';
import { currenciesSelector } from 'store/currencies/selectors';

const FixedInput = memo(({ type, value, defaultValue, currency, defaultCurrency, tabId, currencies = [], field }) => {
  const dispatch = useDispatch();

  const onValueChange = useCallback(
    (event) => {
      dispatch(setServiceCommissionAction({ index: tabId, type, field, value: event.target.value }));
    },
    [dispatch, tabId, type, field],
  );
  const onCurrencyChange = useCallback(
    (currencyValue) => {
      dispatch(setServiceCommissionAction({ index: tabId, type, field: `${field}Currency`, value: currencyValue }));
    },
    [dispatch, tabId, type, field],
  );

  return (
    <FixedInputWrapper>
      <TableCellMaskedInput mask={NUMBER_MASK} value={value} placeholder={defaultValue} onChange={onValueChange} />
      <CommissionSelect
        options={currencies}
        value={currency}
        placeholder={defaultCurrency}
        onChange={onCurrencyChange}
      />
    </FixedInputWrapper>
  );
});

const ROWS = {
  min: memo((props) => <FixedInput field="min" {...props} />),
  fixed: memo((props) => <FixedInput field="fixed" {...props} />),
  percent: memo(({ type, value, defaultValue, tabId }) => {
    const dispatch = useDispatch();

    const onChange = useCallback(
      (event) => {
        dispatch(setServiceCommissionAction({ index: tabId, type, field: 'percent', value: event.target.value }));
      },
      [dispatch, tabId, type],
    );

    return (
      <MaskedInput
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        mask={NUMBER_MASK}
        value={value}
        onChange={onChange}
        placeholder={defaultValue || '0'}
      />
    );
  }),
  failed: memo((props) => <FixedInput field="failed" {...props} />),
  max: memo((props) => <FixedInput field="max" {...props} />),
};

const CommissionsForm = ({ tabId, onDeleteTab }) => {
  const dispatch = useDispatch();
  const { commissions: allCommissions } = useSelector(cashflowFormSelector);
  const commissions = useMemo(() => omit(['serviceName'], allCommissions[tabId]), [allCommissions, tabId]);
  const defaults = useMemo(() => omit(['serviceName'], allCommissions['default']), [allCommissions]);
  const currencies = useSelector(currenciesSelector);

  const onDelete = useCallback(() => onDeleteTab(tabId), [tabId, onDeleteTab]);

  const onChangeServiceName = useCallback(
    (event) => dispatch(setServiceNameAction({ index: tabId, name: event.target.value })),
    [dispatch, tabId],
  );

  return (
    <>
      {tabId !== 'default' ? (
        <ComissionTabControl direction="row" justify="between">
          <TextField label="Service name" value={allCommissions[tabId].serviceName} onChange={onChangeServiceName} />
          <IconButton onClick={onDelete} size="large">
            <Delete />
          </IconButton>
        </ComissionTabControl>
      ) : null}

      <TableContainer>
        <Table size="small" aria-label="Commissions form">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {keys(commissions).map((type) => (
                <TableCell key={type} align="center">
                  {type}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {keys(ROWS).map((rowName) => (
              <TableRow key={rowName}>
                <TableCell>{rowName}</TableCell>
                {keys(commissions).map((type) => {
                  const RowInput = ROWS[rowName];
                  return (
                    <TableCell key={type}>
                      {has(rowName, commissions[type]) ? (
                        <RowInput
                          type={type}
                          value={commissions[type][rowName]}
                          defaultValue={defaults[type][rowName]}
                          tabId={tabId}
                          currency={commissions[type][`${rowName}Currency`]}
                          defaultCurrency={defaults[type][`${rowName}Currency`]}
                          currencies={currencies}
                        />
                      ) : (
                        <>Not applicable</>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(CommissionsForm);
