import { requestsFactory } from 'redux-requests-factory';
import type { AccessData } from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getAccessData = (provider: string): Promise<AccessData> => axiosInstance.get(ENDPOINTS.ACCESS_DATA(provider));

export const {
  forcedLoadDataAction: forceLoadAccessDataAction,
  responseSelector: accessDataResponseSelector,
  resetRequestAction: resetAccessDataAction,
} = requestsFactory({
  request: getAccessData,
  stateRequestKey: 'access-data',
  transformResponse: (response?: AccessData) => response ?? ({} as AccessData),
  includeInGlobalLoading: false,
});
