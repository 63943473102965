import { createSelector } from 'reselect';

export const snapshotFormSelector = (state) => state.snapshotForm;

export const snapshotBodySelector = createSelector([snapshotFormSelector], (snapshotForm) => {
  const { title, data } = snapshotForm;

  const body = {
    title,
    data,
  };

  return body;
});
