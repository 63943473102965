import styled from 'styled-components';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)`
  font-size: 0.75rem;
  letter-spacing: 0.025rem;
  border: none;

  & .MuiDataGrid-columnHeaders {
    background-color: ${({ theme }) => theme.palette.background.panel};
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 500;
  }

  & .MuiDataGrid-columnHeader,
  & .MuiDataGrid-cell {
    :focus,
    :focus-within {
      outline: none;
    }
  }

  & .MuiDataGrid-row:nth-child(odd) {
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  & .MuiDataGrid-row:nth-child(even) {
    background-color: ${({ theme }) => theme.palette.background.form};
  }

  & .MuiDataGrid-cell {
    border: 1px solid ${({ theme }) => theme.palette.background.style2};
    border-top: 0;
    border-right: 0;
  }

  & .MuiDataGrid-cell:nth-last-child(1) {
    border-right: 1px solid ${({ theme }) => theme.palette.background.style2};
  }

  & .MuiDataGrid-columnHeader {
    border-left: 1px solid ${({ theme }) => theme.palette.background.default};
  }

  & .MuiDataGrid-columnHeader:nth-last-child(1) {
    border-right: 1px solid ${({ theme }) => theme.palette.background.default};
  }

  & .MuiDataGrid-cell.ps-status-cell {
    padding: 0;
  }

  & .MuiDataGrid-footerContainer {
    padding: 11px 12px;
    border: 0;
    justify-content: flex-start;
    min-height: auto;
  }

  & .MuiDataGrid-iconButtonContainer {
    visibility: visible;
    width: auto;
  }

  & .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: space-between;
  }
`;
