import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from 'components/atoms/Link';
import AppIcon from 'components/atoms/AppIcon';

import { StyledToolbar, NavMenuWrapper, NavMenuItem, StyledAppBar, StyledTypography } from './Header.styles';

const Header = () => {
  return (
    <StyledAppBar position="static">
      <StyledToolbar variant="dense">
        <Link to="/">
          <Box display="flex" alignItems="center">
            <AppIcon />
            <Box ml={1.5}>
              <StyledTypography variant="h6">PSC</StyledTypography>
            </Box>
          </Box>
        </Link>
        <NavMenuWrapper>
          <NavMenuItem to="/payment_systems">
            <Typography fontWeight={500} variant="caption">
              Payment Systems
            </Typography>
          </NavMenuItem>
          <NavMenuItem to="/cashflows">
            <Typography fontWeight={500} variant="caption">
              Cashflows
            </Typography>
          </NavMenuItem>
          <NavMenuItem to="/snapshots">
            <Typography fontWeight={500} variant="caption">
              Snapshots
            </Typography>
          </NavMenuItem>
        </NavMenuWrapper>
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default Header;
