export const LIMITS_COLUMNS = [
  {
    key: 'min',
    label: 'Min',
  },
  {
    key: 'max',
    label: 'Max',
  },
  {
    key: 'commission_lower_bound',
    label: 'Commission lower bound, %',
  },
  {
    key: 'commission_upper_bound',
    label: 'Commission upper bound, %',
  },
  {
    key: 'commission_percent',
    label: 'Commission percent, %',
  },
  {
    key: 'commission_fixed',
    label: 'Commission fixed',
  },
  {
    key: 'max_auto',
    label: 'Max auto cashout',
  },
];

export const DEPOSIT = 'deposit';
export const CASHOUT = 'cashout';
export const PERCENT_LIMITS = ['commission_percent', 'commission_lower_bound', 'commission_upper_bound'];
export const MIN_FIELDS = ['min', 'commission_lower_bound'];
export const LIMIT_FIELDS = [DEPOSIT, CASHOUT];

export const MIN_MAX_MAP: Record<string, string> = {
  min: 'max',
  commission_lower_bound: 'commission_upper_bound',
};

export const LIMITS_LABELS: Record<string, string> = {
  min: 'Min',
  max: 'Max',
  commission_lower_bound: 'Commission lower bound',
  commission_upper_bound: 'Commission upper bound',
};
