import React from 'react';

const AppIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M5 20C2.23858 20 5.03235e-07 17.7614 1.12401e-06 15L2.24801e-06 10L10 10L10 20L5 20Z" fill="#F2994A" />
    <path d="M10 10L10 -9.53674e-07L15 -2.98004e-07C17.7614 6.41135e-08 20 2.23858 20 5L20 10L10 10Z" fill="#2196F3" />
    <path d="M0 10L2.6229e-06 -9.53674e-07L10 -2.98058e-07L10 10L0 10Z" fill="#FFC200" />
  </svg>
);

export default AppIcon;
