import { requestsFactory } from 'redux-requests-factory';
import type { PaymentProviderSettings } from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getPaymentProviders = (): Promise<PaymentProviderSettings[]> => axiosInstance.get(ENDPOINTS.PAYMENT_PROVIDERS);

export const { loadDataAction: loadPaymentProviders, responseSelector: paymentProvidersResponseSelector } =
  requestsFactory({
    request: getPaymentProviders,
    stateRequestKey: 'payment-providers',
    transformResponse: (response?: PaymentProviderSettings[]) => response ?? [],
  });

const getFilterPaymentProviders = (): Promise<string[]> => axiosInstance.get(ENDPOINTS.FILTER_PAYMENT_PROVIDERS);

export const { loadDataAction: loadFilterPaymentProviders, responseSelector: filterPaymentProvidersResponseSelector } =
  requestsFactory({
    request: getFilterPaymentProviders,
    stateRequestKey: 'filter-payment-providers',
    transformResponse: (response?: string[]) => response ?? [],
    includeInGlobalLoading: false,
  });
