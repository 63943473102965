import { combineEpics, ofType } from 'redux-observable';
import { ignoreElements, mergeMapTo, mapTo, tap } from 'rxjs/operators';
import { NotificationManager } from 'react-notifications';
import { loadCasinosAction } from 'api/casinos';
import { createCasinoSnapshotsFulfilledAction, createCasinoSnapshotsRejectedAction } from 'api/casino-snapshots';

import { initCasinoSnapshotsFormAction, resetCasinoSnapshotsFormAction } from './index';

const initCasinoSnapshotsFormEpic = (action$) =>
  action$.pipe(ofType(initCasinoSnapshotsFormAction), mergeMapTo([loadCasinosAction()]));

const onApplyCasinoSnapshotSuccess = (action$) =>
  action$.pipe(
    ofType(createCasinoSnapshotsFulfilledAction),
    tap(() => {
      return NotificationManager.success('Snapshot applied');
    }),
    mapTo(resetCasinoSnapshotsFormAction()),
  );

const onApplyCasinoSnapshotFail = (action$) =>
  action$.pipe(
    ofType(createCasinoSnapshotsRejectedAction),
    tap(() => {
      NotificationManager.error('Failed to apply snapshot');
    }),
    ignoreElements(),
  );

export default combineEpics(initCasinoSnapshotsFormEpic, onApplyCasinoSnapshotSuccess, onApplyCasinoSnapshotFail);
