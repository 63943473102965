import { requestsFactory } from 'redux-requests-factory';
import type {
  PaymentSystemCreateModel,
  PaymentSystemEror,
  PaymentSystemResponse,
  PaymentSystemUpdateModel,
  PaymentSystemsResponse,
} from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

export interface GetPaymentSystemsRequestParams {
  filters?: Record<string, string[]>;
  page?: string;
  sorting?: string;
}

const getPaymentSystems = (params: GetPaymentSystemsRequestParams = {}): Promise<PaymentSystemsResponse> => {
  return axiosInstance.get(ENDPOINTS.PAYMENT_SYSTEMS, { params });
};

export const {
  loadDataAction: loadPaymentSystemsAction,
  forcedLoadDataAction: forceLoadPaymentSystemsAction,
  responseSelector: paymentsSystemsResponseSelector,
  setResponseAction: setPaymentSystemResponse,
} = requestsFactory({
  request: getPaymentSystems,
  stateRequestKey: 'payment-systems',
  transformResponse: (response?: PaymentSystemsResponse) =>
    response || {
      payment_systems: [],
      pagination: {
        current_page: 1,
        per_page: 50,
        total_count: 0,
        total_pages: 1,
      },
    },
});

const resyncPaymentSystem = (paymentSystemId: number): Promise<PaymentSystemResponse> =>
  axiosInstance.post(ENDPOINTS.RESYNC_PAYMENT_SYSTEM(paymentSystemId));

export const {
  doRequestAction: resyncPaymentSystemAction,
  requestFulfilledAction: resyncPaymentSystemFulfilledAction,
  requestRejectedAction: resyncPaymentSystemRejectedAction,
} = requestsFactory({
  request: resyncPaymentSystem,
  stateRequestKey: 'payment-system-resync',
  includeInGlobalLoading: false,
});

export const getPaymentSystem = (paymentSystemId: number): Promise<PaymentSystemResponse> => {
  return axiosInstance.get(ENDPOINTS.PAYMENT_SYSTEM(paymentSystemId));
};

export const {
  loadDataAction: loadPaymentSystemAction,
  forcedLoadDataAction: forceLoadPaymentSystemAction,
  responseSelector: paymentsSystemResponseSelector,
  isLoadingSelector: isPaymentSystemLoadingSelector,
  resetRequestAction: resetPaymentSystemAction,
  requestFulfilledAction: loadPaymentSystemFulfilledAction,
} = requestsFactory({
  request: getPaymentSystem,
  stateRequestKey: 'payment-system',
});

const updatePaymentSystem = (paymentSystem: PaymentSystemUpdateModel): Promise<PaymentSystemResponse> => {
  return axiosInstance.patch(ENDPOINTS.UPDATE_PAYMENT_SYSTEM(paymentSystem.id), paymentSystem);
};

export const {
  doRequestAction: updatePaymentSystemAction,
  requestFulfilledAction: updatePaymentSystemFulfilledAction,
  requestRejectedAction: updatePaymentSystemRejectedAction,
  errorSelector: updatePaymentSystemErrorSelector,
  resetRequestAction: resetUpdatePaymentSystemAction,
  isLoadedSelector: isUpdateCompletedSelector,
} = requestsFactory({
  request: updatePaymentSystem,
  stateRequestKey: 'payment-system-update',
  transformError: (response): PaymentSystemEror => response?.errors,
});

const createPaymentSystem = (paymentSystem: PaymentSystemCreateModel): Promise<PaymentSystemsResponse> => {
  return axiosInstance.post(ENDPOINTS.CREATE_PAYMENT_SYSTEM, paymentSystem);
};

export const {
  doRequestAction: createPaymentSystemAction,
  requestFulfilledAction: createPaymentSystemFulfilledAction,
  errorSelector: createPaymentSystemErrorSelector,
  resetRequestAction: resetCreatePaymentSystemAction,
  isLoadedSelector: isCreateCompletedSelector,
} = requestsFactory({
  request: createPaymentSystem,
  stateRequestKey: 'payment-system-create',
  transformError: (response): PaymentSystemEror => response?.errors,
});

const getFilterChildSystems = (): Promise<string[]> => axiosInstance.get(ENDPOINTS.FILTER_PAYMENT_SYSTEMS);

export const { loadDataAction: loadFilterChildSystems, responseSelector: filterChildSystemsResponseSelector } =
  requestsFactory({
    request: getFilterChildSystems,
    stateRequestKey: 'filter-child-systems',
    transformResponse: (response?: string[]) => response ?? [],
    includeInGlobalLoading: false,
  });
