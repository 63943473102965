import styled from 'styled-components';
import MaskedInput from 'components/atoms/MaskedInput';
import Select from 'components/molecules/Select';

export const FixedInputWrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

export const CommissionSelect = styled(Select)`
  .MuiSelect-select {
    padding: 12px 14px;
  }
`;

export const ComissionTabControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableCellMaskedInput = styled(MaskedInput)`
  margin: 0;
  margin-right: 5px;
`;
