import { requestsFactory } from 'redux-requests-factory';
import type { CasinosResponse } from 'types/api';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getCasinos = (): Promise<CasinosResponse> => axiosInstance.get(ENDPOINTS.CASINOS);

export const { loadDataAction: loadCasinosAction, responseSelector: casinosResponseSelector } = requestsFactory({
  request: getCasinos,
  stateRequestKey: 'casinos',
  transformResponse: (response?: CasinosResponse) => response?.casinos ?? [],
});
