import type { PaymentSystemLimits } from 'types/api';
import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getLimits = (): Promise<PaymentSystemLimits> => axiosInstance.get(ENDPOINTS.PAYMENT_SYSTEMS_LIMITS);

export const { loadDataAction: loadLimitsAction, responseSelector: limitsResponseSelector } = requestsFactory({
  request: getLimits,
  stateRequestKey: 'limits',
});
