import React from 'react';
import type { FC } from 'react';
import { Grid } from '@mui/material';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { Currency, PaymentSystem } from 'types/api';
import FormMultiSelect from 'components/atoms/FormMultiSelect';
import FormDivider from 'components/atoms/FormDivider';

import { FormSectionTitle } from '../../PaymentSystemForm.styles';

interface CurrenciesSectionProps {
  control: Control<PaymentSystem>;
  currencies: Currency[];
  onCurrenciesChange: (currencies: string[]) => void;
}

export const CurrenciesSection: FC<CurrenciesSectionProps> = ({ control, currencies, onCurrenciesChange }) => {
  return (
    <>
      <Grid item xs={12}>
        <FormSectionTitle>Acceptable currencies</FormSectionTitle>
      </Grid>

      <Grid item xs={10}>
        <Controller
          name="currencies"
          control={control}
          render={({ field }) => (
            <FormMultiSelect
              {...field}
              label="Currencies"
              onChange={(value) => onCurrenciesChange(value)}
              options={currencies.map((c) => ({ value: c.iso_code, label: c.iso_code, disabled: false }))}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
