import styled from 'styled-components';
import { Chip, FormControl, TextField } from '@mui/material';

export const StyledFormControl = styled(FormControl)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text.dark};

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.background.style2};
  }

  & .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

export const StyledMenuItem = styled.li`
  white-space: normal;
  word-wrap: break-word;
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const StyledChip = styled(Chip)`
  color: ${({ theme }) => theme.palette.text.dark};
`;

export const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: ${({ theme }) => theme.palette.text.dark};
  }

  & .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    padding-left: 4px;
  }

  & .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 5px;
  }
`;
