import styled from 'styled-components';
import Box from '@mui/material/Box';

export const Wrapper = styled(Box)`
  padding: 8px 12px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0 1px 2px 0 rgba(78, 121, 135, 0.14);
  margin-bottom: 8px;
`;
