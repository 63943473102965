import { createActions, handleActions } from 'redux-actions';
import { noop } from 'rxjs';
import identity from 'ramda/src/identity';

const DEFAULT_STATE = {
  snapshotId: '',
  casinoIds: [],
  casinosAccessData: '',
};

export const {
  initCasinoSnapshotsFormAction,
  setCasinoSnapshotsIdAction,
  setCasinoSnapshotsCasinosAction,
  setCasinoSnapshotsAccessDataAction,
  resetCasinoSnapshotsFormAction,
} = createActions(
  {
    INIT_CASINO_SNAPSHOTS_FORM_ACTION: noop,
    SET_CASINO_SNAPSHOTS_ID_ACTION: identity,
    SET_CASINO_SNAPSHOTS_CASINOS_ACTION: identity,
    SET_CASINO_SNAPSHOTS_ACCESS_DATA_ACTION: identity,
    RESET_CASINO_SNAPSHOTS_FORM_ACTION: noop,
  },
  {
    prefix: 'SNAPSHOT_FORM',
  },
);

export const casinoSnapshotsFormReducer = handleActions(
  {
    [setCasinoSnapshotsIdAction]: (state, { payload }) => ({ ...state, snapshotId: payload }),
    [setCasinoSnapshotsCasinosAction]: (state, { payload }) => ({ ...state, casinoIds: payload }),
    [setCasinoSnapshotsAccessDataAction]: (state, { payload }) => ({ ...state, casinosAccessData: payload }),
    [resetCasinoSnapshotsFormAction]: () => ({ ...DEFAULT_STATE }),
  },
  DEFAULT_STATE,
);
