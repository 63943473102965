import { Tooltip, Typography } from '@mui/material';
import type { FC } from 'react';
import React from 'react';
import type { PaymentSystemSyncError } from 'types/api';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface SyncErrorTooltipeProps {
  error: PaymentSystemSyncError;
}

const SyncErrorTooltip: FC<SyncErrorTooltipeProps> = ({ error }) => {
  const tooltipTitle = (
    <>
      {error.code && <Typography fontSize="small">Code: {error.code}</Typography>}
      <Typography fontSize="small">{error.message}</Typography>
    </>
  );

  return (
    <Tooltip arrow title={tooltipTitle}>
      <InfoOutlinedIcon fontSize="small" />
    </Tooltip>
  );
};

export default SyncErrorTooltip;
