import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import DataGrid from 'components/molecules/DataGrid';
import StyledLink from 'components/atoms/Link';
import { snapshotsResponseSelector, loadSnapshotsAction } from 'api/snapshots';
import AddButton from 'components/atoms/AddButton';
import StyledBox from 'components/atoms/Box';

const Snapshots = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSnapshotsAction());
  }, [dispatch]);

  const snapshots = useSelector(snapshotsResponseSelector);

  return (
    <Box>
      <StyledBox>
        <StyledLink to="/snapshots/add">
          <AddButton>Add snapshot</AddButton>
        </StyledLink>
      </StyledBox>

      <DataGrid
        columns={[
          {
            field: 'id',
            headerName: 'ID',
          },
          {
            field: 'title',
            flex: 1,
            headerName: 'Title',
          },
        ]}
        rows={snapshots}
      />
    </Box>
  );
};

export default Snapshots;
