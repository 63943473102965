import styled from 'styled-components';
import Box from '@mui/material/Box';

export default styled(Box)`
  display: flex;
  padding: 10px;
  flex-direction: ${({ direction = 'row' }) => direction};
  align-items: ${({ align = 'center' }) => align};
  justify-content: ${({ justify }) => justify};
`;
