import { Grid, type SelectChangeEvent } from '@mui/material';
import FormDivider from 'components/atoms/FormDivider';
import FormSelect from 'components/atoms/FormSelect';
import type { FormSelectOption } from 'components/atoms/FormSelect/FormSelect';
import React from 'react';
import type { FC } from 'react';
import type { Control, FieldErrors } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { PaymentSystem } from 'types/api';

interface ProviderSectionProps {
  control: Control<PaymentSystem>;
  providerNames: FormSelectOption[];
  isEditMode: boolean;
  errors: FieldErrors<PaymentSystem>;
  onProviderChange: (providerName: string) => void;
}

export const ProviderSection: FC<ProviderSectionProps> = ({
  control,
  providerNames,
  isEditMode,
  errors,
  onProviderChange,
}) => {
  const handleProviderChange = (event: SelectChangeEvent<string>) => {
    onProviderChange(event.target.value);
  };

  return (
    <>
      <Grid item xs={5}>
        <Controller
          name="providerName"
          control={control}
          render={({ field }) => (
            <FormSelect
              label="System name"
              {...field}
              options={providerNames}
              required={true}
              disabled={isEditMode}
              onChange={handleProviderChange}
              error={errors.providerName}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <FormDivider />
      </Grid>
    </>
  );
};
