import styled from 'styled-components';

export const ComissionTabControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CashflowControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px 0;
`;
