import { createSelector } from 'reselect';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import { paymentProvidersResponseSelector } from 'api/payment-providers';
import { cashflowsResponseSelector } from 'api/cashflows';
import {
  isCreateCompletedSelector,
  isUpdateCompletedSelector,
  createPaymentSystemErrorSelector,
  updatePaymentSystemErrorSelector,
} from 'api/payment-systems';

export const isSaveCompletedSelector = createSelector(
  [isUpdateCompletedSelector, isCreateCompletedSelector],
  (isUpdateCompleted, isCreateCompleted) => isUpdateCompleted || isCreateCompleted,
);

export const paymentSystemErrorSelector = createSelector(
  [createPaymentSystemErrorSelector, updatePaymentSystemErrorSelector],
  (createError, updateError) => createError || updateError,
);

export const paymentProvidersSelector = createSelector(paymentProvidersResponseSelector, (response) =>
  map(prop('provider_name'), response),
);

export const paymentProvidersOptionsSelector = createSelector(paymentProvidersSelector, (providers) =>
  providers.map((value) => ({ label: value, value: value })),
);

export const systemNamesByProviderSelector = (providerName: string) =>
  createSelector([paymentProvidersResponseSelector], (providersResponse) => {
    const provider = providersResponse.find((p) => p.provider_name === providerName);
    return provider ? map(prop('name'), provider.payment_systems) : [];
  });

export const systemNamesOptionsSelector = (providerName: string) =>
  createSelector(systemNamesByProviderSelector(providerName), (systemNames) =>
    systemNames.map((name) => ({
      label: name,
      value: name,
    })),
  );

export const cashflowsOptionsSelector = createSelector(cashflowsResponseSelector, (cashflows) =>
  cashflows.map(({ id, name }: { id: number; name: string }) => ({ label: name, value: id })),
);
