import type { FC, ReactNode, SyntheticEvent } from 'react';
import React, { useState } from 'react';
import { Tab, type Theme } from '@mui/material';
import type { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import Box from '@mui/material/Box';

import { TabPanel } from './TabPanel';
import { StyledTabs } from './Tabs.styles';

export interface TabItem {
  label: string;
  componet: ReactNode;
  invalid?: boolean;
}

interface TabsProps extends MuiTabsProps {
  tabs: TabItem[];
  activeTabIndex?: number;
  onTabChange?: (index: number) => void;
}

export const Tabs: FC<TabsProps> = ({ tabs, activeTabIndex, onTabChange, ...other }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);

    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  const getLabelColor = (theme: Theme, invalid?: boolean) => {
    return invalid ? theme.palette.error.main : 'inherit';
  };

  const safeValue = activeTabIndex ?? (value >= tabs.length ? 0 : value);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex' }}>
        <StyledTabs value={safeValue} onChange={handleChange} variant="scrollable" scrollButtons="auto" {...other}>
          {tabs.map(({ label, invalid }, i) => (
            <Tab
              label={label}
              key={i}
              sx={(theme: Theme) => ({
                color: getLabelColor(theme, invalid),
                '&.Mui-selected': {
                  color: getLabelColor(theme, invalid),
                },
              })}
            />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map(({ componet }, i) => (
        <TabPanel value={safeValue} index={i} key={i}>
          {componet}
        </TabPanel>
      ))}
    </Box>
  );
};
