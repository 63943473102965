import { PaymentSystemStatus } from 'constants/paymentSystemStatus';

import type { PaymentSystemSyncError } from 'types/api';

export interface PaymentSystemSyncStatus {
  id: number;
  status: PaymentSystemStatus;
  error?: PaymentSystemSyncError;
}

export const getSyncStatusButtonText = (status: PaymentSystemStatus): string => {
  switch (status) {
    case PaymentSystemStatus.SYNCED:
      return 'Synced';
    case PaymentSystemStatus.NON_SYNCED:
      return 'Resync';
    case PaymentSystemStatus.SYNC_RETRYING:
      return 'Syncing';
  }
};

export const getStatusText = (status: string): string => {
  switch (status) {
    case PaymentSystemStatus.SYNCED:
      return 'Synced';
    case PaymentSystemStatus.NON_SYNCED:
      return 'Not synced';
    case PaymentSystemStatus.SYNC_RETRYING:
      return 'Sync retrying';
    default:
      return '';
  }
};

export const isPaymentSystemNonSynced = (status: PaymentSystemStatus): boolean => {
  return status === PaymentSystemStatus.NON_SYNCED;
};
