import type { IconName } from 'constants/iconName';

import React from 'react';

interface SvgIconProps {
  name: IconName;
}

const SvgIcon = ({ name }: SvgIconProps) => {
  const iconPath = `${process.env.PUBLIC_URL}/icons/${name}.svg`;

  return <img src={iconPath} alt={`${name} icon`} />;
};

export default SvgIcon;
