import React, { forwardRef } from 'react';
import type { ControllerRenderProps, FieldError } from 'react-hook-form';
import { FormHelperText, type TextFieldProps } from '@mui/material';

import { StyledTextField } from './FormTextField.styles';

export interface FormTextFieldProps
  extends Omit<ControllerRenderProps, 'ref'>,
    Pick<TextFieldProps, 'InputProps' | 'inputProps' | 'type'> {
  label?: string;
  multiline?: boolean;
  required?: boolean;
  error?: FieldError;
  showError?: boolean;
}

const FormTextField = forwardRef(
  (
    { label, multiline = false, required = false, error, type, value, showError = true, ...props }: FormTextFieldProps,
    ref,
  ) => {
    return (
      <>
        <StyledTextField
          {...props}
          value={value ?? ''}
          type={type}
          inputRef={ref}
          label={label}
          variant="outlined"
          fullWidth
          multiline={multiline}
          required={required}
          size="small"
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          error={!!error}
        />
        {showError && error?.message && <FormHelperText error>{error.message}</FormHelperText>}
      </>
    );
  },
);

export default FormTextField;
