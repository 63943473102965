import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Cashflows from 'components/organisms/Cashflows';
import CashflowForm from 'components/organisms/CashflowForm';

export default function () {
  return (
    <Routes>
      <Route index element={<Cashflows />} />
      <Route path="add" element={<CashflowForm />} />
    </Routes>
  );
}
