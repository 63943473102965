import { AppBar, Box, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: 90%;
  }
`;

export const DrawerAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 1px 2px 0px rgba(78, 121, 135, 0.14);
`;

export const DrawerToolbar = styled(Toolbar)`
  padding: 0;
`;

export const DrawerContent = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.form};
  height: 100%;
  overflow-y: auto;
`;

export const DrawerTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.drawerTitle};
`;

export const DrawerIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.icon};
`;
