import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const INTEGER_MASK = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: false,
});
export const NUMBER_MASK = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
});
