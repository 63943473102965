import React from 'react';
import type { FC, ReactNode } from 'react';

import { StyledActionButton } from './ActionButton.styles';

interface ActionButtonProps {
  onClick: () => void;
  children?: ReactNode;
  disabled?: boolean;
}

const ActionButton: FC<ActionButtonProps> = ({ onClick, children, disabled }) => {
  return (
    <StyledActionButton type="button" onClick={onClick} disabled={disabled}>
      {children}
    </StyledActionButton>
  );
};

export default ActionButton;
