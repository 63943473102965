import React from 'react';
import type { FC, PropsWithChildren } from 'react';

import { StyledButton } from './AddButton.styles';

interface AddButtonProps {
  onClick?: () => void;
}

const AddButton: FC<PropsWithChildren<AddButtonProps>> = ({ children, onClick }) => (
  <StyledButton variant="contained" onClick={onClick}>
    {children}
  </StyledButton>
);

export default AddButton;
