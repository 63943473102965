import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toPairs from 'ramda/src/toPairs';
import omit from 'ramda/src/omit';
import { saveCashflowAction } from 'api/cashflows';
import { cashflowBodySelector, cashflowFormSelector } from 'store/cashflow-form/selectors';
import {
  initCashflowFormAction,
  setNameAction,
  setCurrencyAction,
  setAcceptableCurrenciesAction,
  addServiceAction,
  deleteServiceAction,
} from 'store/cashflow-form';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tabs, { TabPanel } from 'components/molecules/Tabs';
import Select from 'components/molecules/Select';
import { Box } from '@mui/material';

import CommissionsForm from './subcomponents/CommissionsForm';
import { CashflowControl } from './Cashflow.styles';
import { currenciesSelector } from 'store/currencies/selectors';

const Cashflow = () => {
  const dispatch = useDispatch();
  const [comissionTab, setComissionTab] = useState(0);

  useEffect(() => {
    dispatch(initCashflowFormAction());
  }, []);

  const { name, currency, acceptableCurrencies, commissions } = useSelector(cashflowFormSelector);
  const saveRequestBody = useSelector(cashflowBodySelector);
  const onSaveClick = useCallback(() => dispatch(saveCashflowAction(saveRequestBody)), [saveRequestBody, dispatch]);
  const onAddServiceClick = useCallback(() => dispatch(addServiceAction()), [dispatch]);
  const onChangeName = useCallback((event) => dispatch(setNameAction(event.target.value)), [dispatch]);
  const currenciesOptions = useSelector(currenciesSelector);
  const onChangeCurrency = useCallback((value) => dispatch(setCurrencyAction(value)), [dispatch]);
  const onChangeAcceptableCurrencies = useCallback((value) => dispatch(setAcceptableCurrenciesAction(value)), [
    dispatch,
  ]);

  const onDeleteTab = useCallback(
    (tabId) => {
      dispatch(deleteServiceAction(tabId));

      if (comissionTab === tabId) {
        setComissionTab(0);
      }
    },
    [dispatch, comissionTab],
  );

  return (
    <Box ml={20} mr={20} p={3}>
      <Typography variant="h4" align="center">
        New Cashflow
      </Typography>
      <CashflowControl>
        <Button variant="outlined" onClick={onSaveClick}>
          Save
        </Button>
      </CashflowControl>
      <Grid container md={12} spacing={5}>
        <Grid item md={3}>
          <Typography variant="h5">Common</Typography>

          <TextField label="Name" fullWidth value={name} onChange={onChangeName} sx={{marginBottom:2, marginTop:2}} />

          <FormControl fullWidth sx={{marginBottom:2, marginTop:2}}>
            <InputLabel>Currency</InputLabel>
            <Select label="Currency" value={currency} options={currenciesOptions} onChange={onChangeCurrency} />
          </FormControl>

          <FormControl fullWidth sx={{marginBottom:2, marginTop:2}}>
            <InputLabel>Acceptable currencies</InputLabel>
            <Select
              label="Acceptable currencies"
              multiple
              fullWidth
              value={acceptableCurrencies}
              options={currenciesOptions}
              onChange={onChangeAcceptableCurrencies}
              placeholder="Acceptable currencies"
            />
          </FormControl>
        </Grid>
        <Grid item md={9}>
          <Typography variant="h5">Commissions</Typography>
          <Tabs
            addButton={
              <IconButton variant="outlined" onClick={onAddServiceClick} size="large">
                <Add />
              </IconButton>
            }
            activeIndex={comissionTab}
            onChange={setComissionTab}
            variant="scrollable"
            scrollButtons="auto"
          >
            <TabPanel id="default" title="default">
              <CommissionsForm tabId="default" />
            </TabPanel>
            {toPairs(omit(['default'], commissions)).map(([tabId, service]) => (
              <TabPanel title={service.serviceName || 'New'} key={tabId}>
                <CommissionsForm tabId={tabId} onDeleteTab={onDeleteTab} />
              </TabPanel>
            ))}
          </Tabs>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cashflow;
