import { currenciesResponseSelector } from 'api/currencies';
import { createSelector } from 'reselect';

export const currenciesSelector = createSelector(currenciesResponseSelector, (currenciesResponse) =>
  currenciesResponse.currencies.map((currency) => currency.iso_code),
);

export const currenciesOptionsSelector = createSelector(currenciesResponseSelector, (currenciesResponse) =>
  currenciesResponse.currencies.map((value) => ({ label: value.iso_code, value: value.iso_code })),
);

export const currenciesMultiSelectOptionsSelector = createSelector(currenciesOptionsSelector, (options) =>
  options.map((option) => ({ ...option, disabled: false })),
);
