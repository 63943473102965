import { requestsFactory } from 'redux-requests-factory';

import axiosInstance from './axios';
import { ENDPOINTS } from './constants';

const getFilterStatuses = (): Promise<string[]> => axiosInstance.get(ENDPOINTS.FILTER_STATUSES);

export const { loadDataAction: loadFilterStatuses, responseSelector: filterStatusesResponseSelector } = requestsFactory(
  {
    request: getFilterStatuses,
    stateRequestKey: 'filter-statuses',
    transformResponse: (response?: string[]) => response ?? [],
    includeInGlobalLoading: false,
  },
);
